import { FC } from "react";

import { CreateUserDetailLinkProps } from "./types";

export const CreateUserDetailLink: FC<CreateUserDetailLinkProps> = ({
  item,
  type,
}) => (
  <a
    className="collapse-container__list__item__content--link"
    href={`${type}:${item}`}
  >
    {item}
  </a>
);
