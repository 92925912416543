import { HiFlag } from "react-icons/hi";

import "./scss/index.scss";

export const RedFlag = () => (
  <div className="red-flag-icon">
    <HiFlag data-testid="red-flag-icon" />
  </div>
);

export const GrayFlag = () => (
  <div className="gray-flag-icon">
    <HiFlag data-testid="gray-flag-icon" />
  </div>
);
