import { FC, useContext } from "react";

import { TableHeaderItemProps } from "./types";
import { TableContext } from "@/components/Table/context";
import { onClick } from "./utils/onClick";
import { TableHeaderItemIcon } from "./components/TableHeaderItemIcon";
import { useSearchParams } from "@/hooks/useSearchParams";

import "./scss/index.scss";

export const TableHeaderItem: FC<TableHeaderItemProps> = ({
  header,
  resetExpandState,
}) => {
  const { searchParams } = useSearchParams();
  const { updateSearchParams } = useContext(TableContext);
  const { title, apiParam } = header;
  const headerApiParam: string = Array.isArray(apiParam)
    ? apiParam[0]
    : apiParam;

  const sortBy: string | null = searchParams.get("sort_by");

  return (
    <th
      className="table-header"
      onClick={() =>
        onClick({
          updateSearchParams,
          headerApiParam,
          resetExpandState,
          sortBy,
        })
      }
    >
      <span className="header-title">
        {title}
      </span>
      <TableHeaderItemIcon
        {...{
          headerApiParam,
          sortBy,
        }}
      />
    </th>
  );
};
