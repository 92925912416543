import { CreateUserDetail } from "./components/CreateUserDetail";
import { CreateUserDetailLink } from "./components/CreateUserDetailLink";
import { UserDetailListOptions } from "./types";

export const getUserDetailList = (key: string, item: string | string[]) => {
  const property: string = key.toLowerCase();

  const options: UserDetailListOptions = {
    phone: <CreateUserDetailLink {...{ item, type: "tel" }} />,
    mobile: <CreateUserDetailLink {...{ item, type: "tel" }} />,
    email: <CreateUserDetailLink {...{ item, type: "mailto" }} />,
  };

  return options[property] || <CreateUserDetail {...{ item }} />;
};
