import { FC } from "react";

import { Icon } from "../Icon";
import { badgeLogic } from "./utils/badgeLogic";
import { BadgeProps } from "./types";

import "./scss/index.scss";

/**
 *
 * {@link Badge | Badge}
 *
 * @param label - Text to render
 * @param size - Options "small" | "medium" | "large"
 * @param color - Color options "primary" | "neutral" | "success" | "warning" | "destructive"
 * @param state - Style options "primary" | "secondary" | "outlined"
 * @param leftIcon - Icon to be rendered
 * @param rightIcon - Icon to be rendered
 * @param disabled - Render or not on in disabled state
 * @param className - ClassName to add in case for custom styles
 * @param onClick - Function to run when ever the badge gets clicked
 *
 * @returns The JSX element, Badge component
 *
 * @example
 * Simple usage:
 * ```
 * <Badge label={"callback"} size={"small"}
 * color={"primary"} state={"primary"} />
 * ```
 *
 * @example
 * With icons:
 * ```
 * <Badge label={"callback"} size={"small"}
 *  color={"primary"} state={"primary"}
 *  LeftIcon={IoAccessibility}
 *  RightIcon={IoAccessibility} />
 * ```
 *
 * @example
 * With disabled state on:
 * ```
 * <Badge label={"callback"} size={"small"}
 *  color={"primary"} state={"primary"}
 *  LeftIcon={IoAccessibility}
 *  RightIcon={IoAccessibility}
 *  disabled />
 * ```
 *
 * @privateRemarks
 * {@link https://bloodbuy.atlassian.net/browse/BB-4352 - Jira Story}
 *
 * {@link https://www.figma.com/file/RLThK9XjWLu4wGKzGjqaxa/Bloodbuy-Badges?node-id=2%3A3723&t=tU0Rpz8LcYHt5TBt-0 - Figma design}
 */
export const Badge: FC<BadgeProps> = ({
  label,
  leftIcon,
  rightIcon,
  size,
  color,
  disabled,
  state,
  onClick: callback,
  className: appendedClass = "",
}) => {
  const { onClick, className, iconColor } = badgeLogic({
    size,
    state,
    color,
    disabled,
    appendedClass,
    callback,
    label,
  });

  return (
    <div onClick={onClick} className={className}>
      <Icon
        {...{
          icon: leftIcon,
          testID: "left-icon",
          state: "link",
          disabled,
          color: iconColor,
        }}
      />
      <span>{label}</span>
      <Icon
        {...{
          icon: rightIcon,
          testID: "right-icon",
          state: "link",
          disabled,
          color: iconColor,
        }}
      />
    </div>
  );
};
