import { FC, memo, useContext } from "react";

import { TableRowItemProps } from "./types";
import { TableRowActions } from "./components/TableRowActions";
import { TableRowIcon } from "./components/TableRowIcon";
import { TableContext } from "@/components/Table/context";
import { TableRowBulkAction } from "./components/TableRowBulkAction";
import { TableRowHeaders } from "./components/TableRowHeaders";

import "./styles.scss";

export const TableRowItem: FC<TableRowItemProps> = memo(
  ({ record, expandStateHandler, expandState, rowHeaders, isDropDown }) => {
    const recordID: number = record.id;
    const { actions } = useContext(TableContext);

    return (
      <>
        <tr
          onClick={() => expandStateHandler?.(recordID)}
          className={`table-row ${isDropDown ? "clickable" : ""}`}
        >
          <TableRowBulkAction {...{ record }} />
          <td>
            {isDropDown ? (
              <TableRowIcon {...{ expandState, recordID }} />
            ) : null}
          </td>
          <TableRowHeaders {...{ rowHeaders, record }} />
        </tr>

        {actions ? <TableRowActions {...{ actions, record }} /> : null}
      </>
    );
  }
);
