import { MutableRefObject, useRef } from "react";

type Todo = any;

/**
 * Custom hook to handle focus on an input element
 * @returns {MutableRefObject<HTMLInputElement>} - A mutable ref object containing the HTML input element
 * @returns {() => void} - A callback function to set focus on the input element
 */
export const useFocus: () => MutableRefObject<HTMLInputElement> | Todo = () => {
  const htmlElRef = useRef<HTMLInputElement | null>(null);
  const setFocus = () => {
    htmlElRef.current && htmlElRef.current.focus();
  };

  return [htmlElRef, setFocus];
};
