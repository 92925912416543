import { SetStateAction } from "react";

export const updatePermissions = <
  T extends Record<string, any>,
>(setPermissions: {
  (value: SetStateAction<T[]>): void;
}) => {
  return (permission: T) =>
    setPermissions((previousPermissions) => {
      const permissionIndex = previousPermissions.findIndex(
        (previousPermission) => previousPermission.id === permission.id
      ); // assuming permission object has an id property

      const updatedPermissions =
        permissionIndex !== -1
          ? previousPermissions.filter(
              (previousPermission) => previousPermission.id !== permission.id
            )
          : [...previousPermissions, permission];

      return updatedPermissions;
    });
};
