import { FC } from "react";

import { getURL } from "@/utils/getURL";

import { Table } from "@/components/Table";
import { UserDetails } from "@/components/Table/templates/UserDetails";

import { NetworkFacilitiesType } from "./types/networkFacilities";

const url: string = getURL("api:network-list", { parent_lookup_pk: "current" });

export const NetworkFacilities: FC = () => (
  <Table<NetworkFacilitiesType>
    id={"facility_id"}
    navbar={{ header: "Facilities in Network", input: true }}
    dropDown={{
      template: UserDetails,
      props: {
        collapseRow: {
          id: "facility_id",
          primaryMessage: "PRIMARY CONTACT",
          options: {
            Name: ["primary_contact", "full_name"],
            Title: ["primary_contact", "title"],
            Phone: ["primary_contact", "formatted_office_number"],
            Fax: ["primary_contact", "formatted_fax_number"],
            Email: ["primary_contact", "email"],
          },
        },
      },
    }}
    headers={[
      { title: "FACILITY", apiParam: "name" },
      { title: "TYPE", apiParam: "facility_type" },
      { title: "LOCATION", apiParam: "location" },
    ]}
    url={url}
    actions={["view_profile", "submit_disposition_form", "submit_recall"]}
  />
);
