import { FC, useContext } from "react";

import { TableHeaderItem } from "./components/TableHeaderItem";
import { TableHeadersProps } from "./types";
import { EmptyHeader } from "./components/EmptyHeader";
import { TableHeadersBulkAction } from "./components/TableHeadersBulkAction";
import { TableContext } from "@/components/Table/context";

import "./scss/index.scss";

export const TableHeaders: FC<TableHeadersProps> = ({
  tableHeaders,
  resetExpandState,
}) => {
  const { dropDown, isTableLoading } = useContext(TableContext);
  const isDropDown: boolean = Boolean(dropDown);
  const emptyHeaderWidth: string = isTableLoading
    ? "1.5"
    : isDropDown
    ? "36px"
    : "0";

  return (
    <thead className="table-headers-container">
      <tr className="table-row">
        <TableHeadersBulkAction />
        <EmptyHeader width={emptyHeaderWidth} />
        {tableHeaders.map((header) => (
          <TableHeaderItem
            key={header.title}
            header={header}
            resetExpandState={resetExpandState}
            tableHeaders={tableHeaders}
          />
        ))}
        <EmptyHeader width="60px" />
      </tr>
    </thead>
  );
};
