import { FiltersType } from "@/components/Table/types/TableFilters";

export const removeFilters = (
  setFilters: (value: FiltersType[]) => void,
  deleteSearchParam: (value: string) => void,
  filters: FiltersType[]
) => {
  setFilters([]);
  filters.forEach((filter) => deleteSearchParam(filter.header.value));
};
