import { useEffect, useState } from "react";
import { recordsIDSerializer } from "./tools/recordsIDSerializer";

export const useRecords = <T extends Record<string,any>>(
  id: string,
  results?: T[] | null
) => {
  const [records, setRecords] = useState<T[] | null>(null);

  useEffect(() => {
    if (!results) return setRecords(null);
    setRecords(recordsIDSerializer(id, results));
  }, [results, id]);

  const updateRecord = (value: T, prop: string, id: number) => {
    setRecords((prevRecords) => {
      const updatedRecords = prevRecords ? [...prevRecords] : [];
      const record = updatedRecords.find((record: T) => record.id === id);

      if (record) (record as any)[prop] = value;

      return updatedRecords;
    });
  };

  return {
    updateRecord,
    records: records || [],
  };
};
