import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { CustomDatePickerInput } from "../CustomDatePickerInput";
import { CustomDatePickerHeader } from "./components/CustomDatePickerHeader";
import { DropdownSingleSelect } from "@/components/DropdownSingleSelect";
import { useDatePickerLogic } from "./hooks/useDatePickerLogic";
import { filterDate } from "./utils/filterDate";
import { DATE_FORMAT, DEFAULT_DROPDOWN_HEADER } from "./constants";
import { formatDate } from "./utils/formatDate";

import "./scss/index.scss";

export const DatePicker = () => {
  const {
    changeMonthRef,
    changeYearRef,
    dateRangeOptions,
    handleUpdateSelectedDateRange,
    handleUpdateDateRange,
    dateRange,
    dateFilterOptions,
    updateSearchParams,
    startDate,
    endDate,
    allowFutureDates,
    dateField,
  } = useDatePickerLogic();

  const hasMultipleFilterOptions: boolean = dateFilterOptions.length > 1;

  return (
    <div className="date-picker-container">
      {hasMultipleFilterOptions ? (
        <DropdownSingleSelect
          className="date-picker-dropdown"
          options={dateFilterOptions}
          selectedOption={dateField}
          onClick={({ value = "" }) =>
            updateSearchParams?.("date_field", value)
          }
        />
      ) : null}
      <div className="date-picker-sub-container">
        <DropdownSingleSelect
          className="date-picker-dropdown update-date-range"
          options={dateRangeOptions.map(([title, getDays]) => ({
            title,
            onClick: () => {
              getDays(handleUpdateDateRange);
              handleUpdateSelectedDateRange(title);
            },
          }))}
          selectedOption={dateRange || DEFAULT_DROPDOWN_HEADER}
        />

        <ReactDatePicker
          renderCustomHeader={(props) => {
            changeMonthRef.current = props.changeMonth;
            changeYearRef.current = props.changeYear;

            return (
              <CustomDatePickerHeader
                {...{
                  ...props,
                }}
              />
            );
          }}
          showPopperArrow={false}
          dateFormat={DATE_FORMAT}
          selected={startDate ? formatDate(startDate) : null}
          startDate={startDate ? formatDate(startDate) : null}
          filterDate={
            !allowFutureDates?.includes(dateField) ? filterDate : undefined
          }
          customInput={
            <CustomDatePickerInput
              key={endDate || startDate}
              {...{
                handleUpdateDateRange,
                changeMonthRef,
                changeYearRef,
                handleUpdateSelectedDateRange,
              }}
            />
          }
          endDate={endDate ? formatDate(endDate) : null}
          selectsRange
          onChange={([startDate, endDate]) => {
            handleUpdateSelectedDateRange("Custom Range");
            handleUpdateDateRange?.({
              startDate,
              endDate,
            });
          }}
          monthsShown={2}
          disabledKeyboardNavigation
        />
      </div>
    </div>
  );
};
