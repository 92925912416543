import { FC } from "react";

import { Tabs } from "@/components/Tabs";

export const OrderToolsTabs: FC = () => {
  const { pathname } = window.location;

  const [tab, subTab] = pathname
    .split("/")
    .filter((nonEmptyValue) => nonEmptyValue)
    .slice(2);

  return (
    <Tabs
      activeTab={tab}
      activeSubTab={subTab}
      title={"Order Management"}
      tabs={[
        {
          title: "Orders",
          subTabs: [
            {
              title: "All",
              url: "/internal/order-management/orders/all",
            },
            {
              title: "Ordered",
              url: "/internal/order-management/orders/ordered",
            },
            {
              title: "In Transit",
              url: "/internal/order-management/orders/in-transit",
            },
            {
              title: "Delivered",
              url: "/internal/order-management/orders/delivered",
            },
            {
              title: "Flagged",
              url: "/internal/order-management/orders/flagged",
            },
            {
              title: "Canceled",
              url: "/internal/order-management/orders/canceled",
            },
          ],
        },
        {
          title: "Standing Orders",
          subTabs: [
            {
              title: "All",
              url: "/internal/order-management/standing-orders/all",
            },
            {
              title: "Triggering Soon",
              url: "/internal/order-management/standing-orders/triggering-soon",
            },
            {
              title: "Active",
              url: "/internal/order-management/standing-orders/active",
            },
            {
              title: "Paused",
              url: "/internal/order-management/standing-orders/paused",
            },
            {
              title: "Inactive",
              url: "/internal/order-management/standing-orders/inactive",
            },
          ],
        },
        {
          title: "Packages",
          subTabs: [
            {
              title: "All",
              url: "/internal/order-management/packages/all",
            },
            {
              title: "Online",
              url: "/internal/order-management/packages/online",
            },
            {
              title: "Offline",
              url: "/internal/order-management/packages/offline",
            },
            {
              title: "Purchased",
              url: "/internal/order-management/packages/purchased",
            },
            {
              title: "Archived",
              url: "/internal/order-management/packages/archived",
            },
            {
              title: "Private Packages",
              url: "/internal/order-management/packages/private-packages",
            },
          ],
        },
        {
          title: "Open Orders",
          subTabs: [
            {
              title: "All",
              url: "/internal/order-management/open-orders/all",
            },
            {
              title: "Active",
              url: "/internal/order-management/open-orders/active",
            },
            {
              title: "Archived",
              url: "/internal/order-management/open-orders/archived",
            },
            {
              title: "Fulfilled",
              url: "/internal/order-management/open-orders/fulfilled",
            },
            {
              title: "Direct",
              url: "/internal/order-management/open-orders/direct",
            },
          ],
        },
      ]}
    />
  );
};
