import debounce from "lodash.debounce";

import { useFilteredOptions } from "./useFilteredOptions";
import { useInputvalue } from "./useInputvalue";
import { DropdownOptionProps } from "@/components/DropdownMenu/types";
import { useOpen } from "@/components/DropdownSingleSelect/hooks/useOpen";
import { useGetDropdownRef } from "@/components/DropdownSingleSelect/hook/useGetDropdownRef";

export const useDropdownSingleSelectSearchableLogic = <T>(
  options: DropdownOptionProps<T>[],
  reopenOnOptionClick?: boolean
) => {
  const customOptions: DropdownOptionProps<T>[] = options.map((option) => ({
    ...option,
    onClick: option.onClick
      ? ({ value, title }) => {
          resetDropdownContent(reopenOnOptionClick);
          option.onClick?.({ value, title });
        }
      : undefined,
  }));

  const { filteredOptions, filteredOptionsHandler, isAnyFilteredOption } =
    useFilteredOptions(customOptions);

  const { inputValue, inputValueHandler } = useInputvalue();

  const { open, openHandler, dropdownRef } = useOpen();

  const inputRef = useGetDropdownRef([open, openHandler]);

  const delayTime: number = 150;

  const resetDropdownContent = (reopenOnOptionClick: boolean = false) => {
    filteredOptionsHandler(customOptions);
    inputValueHandler("");
    if (inputRef.current) {
      inputRef.current.value = "";
      reopenOnOptionClick &&
        setTimeout(() => inputRef.current.focus(), delayTime);
    }
  };

  const onFocus = () => openHandler(true);

  const onChange = (value: string) => {
    const filteredResults = customOptions.filter(({ title }) =>
      title.toString().toLowerCase().includes(value.toLowerCase())
    );

    filteredOptionsHandler(filteredResults);
    inputValueHandler(value);
  };

  const debouncedOnChange = debounce(onChange, 500);

  return {
    filteredOptions,
    dropdownRef,
    isAnyFilteredOption,
    inputValue,
    open,
    resetDropdownContent,
    onFocus,
    debouncedOnChange,
    inputRef,
  };
};
