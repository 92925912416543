import { FC } from "react";

import { getURL } from "@/utils/getURL";
import { Table } from "@/components/Table";
import { useFilters } from "@/hooks/useFilters";

const url: string = getURL("api:internal-packages-archived");
const filtersURL: string = getURL("api:internal-packages-schema-options");

export const ArchivedPackages: FC = () => {
  const filterOptions = useFilters(filtersURL,
      ["buyer", "has_block", "is_private", "shared_with", "pkg_status"]);

  return (
    <Table
      id={"id"}
      sortBy="-last_modified"
      navbar={{ header: "Archived Packages", input: true }}
      headers={[
        { title: "#", apiParam: "id" },
        { title: "MODIFIED", apiParam: "last_modified", type: "date" },
        { title: "SELLER", apiParam: "seller" },
        { title: "ITEMS", apiParam: "component_string" },
        { title: "LISTED FOR", apiParam: "listed_for" },
        { title: "AUDIENCE", apiParam: "audience" },
      ]}
      filters={filterOptions}
      url={url}
    />
  );
};
