import { useState } from "react";

import { UseDropdownCheckbox } from "./types";
import { DropdownOptionTitle } from "@/components/DropdownMenu/types";

export const useDropdownCheckbox = (): UseDropdownCheckbox => {
  const [dropdownCheckboxes, setDropdownCheckboxes] = useState<
    DropdownOptionTitle[]
  >([]);

  const dropdownCheckboxesHandler = (value: DropdownOptionTitle) => {
    if (dropdownCheckboxes.includes(value))
      return setDropdownCheckboxes(
        dropdownCheckboxes.filter(
          (checkbox: DropdownOptionTitle) => checkbox !== value
        )
      );

    return setDropdownCheckboxes((prevCheckboxes: DropdownOptionTitle[]) => [
      ...prevCheckboxes,
      value,
    ]);
  };

  const dropdownCheckboxesSingleSelect = (value: DropdownOptionTitle) =>
    setDropdownCheckboxes([value]);

  return {
    dropdownCheckboxes,
    dropdownCheckboxesHandler,
    dropdownCheckboxesSingleSelect,
  };
};
