import { FC } from "react";
import { FiCheck } from "react-icons/fi";
import { IoMdClose } from "react-icons/io";

import { Button } from "@/components/Button";
import { Icon } from "@/components/Icon";
import { Badge } from "@/components/Badge";
import { DropdownSingleSelect } from "@/components/DropdownSingleSelect";
import { FiltersContainerProps } from "./types";
import { useFiltersContainerLogic } from "./hooks/useContainerLogic";
import { FiltersType } from "../../types/TableFilters";
import { DropdownSingleSelectSearchable } from "@/components/DropdownSingleSelectSearchable";

import "./scss/index.scss";

export const FiltersContainer: FC<FiltersContainerProps> = ({
  label,
  icon,
  size,
  color,
}) => {
  const {
    ref,
    toggleOpenFilter,
    clickedOutside,
    oncloseModal,
    filterByOptions,
    subHeaderOptions,
    dataHandler,
    data,
    submitFilters,
  } = useFiltersContainerLogic();

  const maxFilterOptionsCount: number = 10;

  return (
    <div ref={ref} className="filter-container">
      {!label ? (
        <Icon
          icon={icon}
          size={size}
          color={color}
          state={"link"}
          onClick={toggleOpenFilter}
          type="button"
        />
      ) : (
        <Badge
          label={label}
          size={size}
          color={color}
          state={"outlined"}
          onClick={toggleOpenFilter}
          leftIcon={icon}
        />
      )}

      {clickedOutside ? (
        <div className="filter-modal-container">
          <div className="close-icon-container">
            <Icon
              icon={IoMdClose}
              state={"link"}
              color="neutral"
              size="small"
              onClick={oncloseModal}
              type="button"
            />
          </div>
          <DropdownSingleSelect
            options={filterByOptions}
            header={"Filter by"}
            onClick={(content) =>
              dataHandler({
                header: content as FiltersType["header"],
                subHeader: {
                  title: "",
                  value: "",
                },
              })
            }
            selectedOption={data.header.title}
          />
          {data.header.title ? (
            subHeaderOptions.length > maxFilterOptionsCount ? (
              <DropdownSingleSelectSearchable<string>
                selectedOption={data.subHeader.title}
                options={subHeaderOptions}
                header={data.header.title.toString()}
                onClick={({ title, value = "" }) =>
                  dataHandler({
                    ...data,
                    subHeader: { title: String(title), value },
                  })
                }
              />
            ) : (
              <DropdownSingleSelect<string>
                selectedOption={data.subHeader.title}
                options={subHeaderOptions}
                header={data.header.title.toString()}
                onClick={({ title, value = "" }) =>
                  dataHandler({
                    ...data,
                    subHeader: { title: String(title), value },
                  })
                }
              />
            )
          ) : null}
          <Button
            label="Apply Filter"
            size={"medium"}
            color={"primary"}
            state={"primary"}
            className={"flex-button"}
            leftIcon={FiCheck}
            disabled={!data.subHeader.title}
            onClick={submitFilters}
            testID="apply-filter-button"
          />
        </div>
      ) : null}
    </div>
  );
};
