type UseFacilityProps = {
  addFacility: (value: string[]) => void;
  facilities: string[];
  removeEmail: (value: string) => void;
  emailsHandler: (value: string) => void;
  emails: string[];
  errorHandler: (error: string) => void;
};

export const useFacility = ({
  addFacility,
  emailsHandler,
  facilities,
  removeEmail,
  emails,
  errorHandler,
}: UseFacilityProps) => {
  const facilityHandler = (record: Record<string, any>) => {
    if (facilities.includes(record.id))
      return errorHandler("Facility already in list");

    const email: string = record.name;
    addFacility([...facilities, record.id]);
    emailsHandler(email);
  };

  const removeFacility = (index: number) => {
    const emailToDelete: string = emails[index];
    removeEmail(emailToDelete);

    const numberOfValuesToRemove: number = 1;
    facilities.splice(index, numberOfValuesToRemove);
    addFacility(facilities);
  };

  return { facilityHandler, removeFacility };
};
