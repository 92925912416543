import { MdEdit } from "react-icons/md";

import { Button } from "@/components/Button";
import { CheckBox } from "@/components/CheckBox";
import { ReviewProps } from "./types";
import { contentByType } from "./utils/contentByType";
import { UpArrowIcon } from "./assets/UpArrowIcon";

import "./scss/index.scss";

export const Review = ({
  review,
  data,
  user,
  reviewHandler,
  sendCopyHandler,
  sendCopyRef,
  onCloseModal,
  submitEmail,
  type,
}: ReviewProps) => (
  <div className="custom-mailer-container">
    <div className="custom-mailer-review-header">Review Email</div>

    <div className="section">
      <div className="section-header">From:</div>
      <div className="section-content">{user.email}</div>
    </div>

    <div className="section">
      <div className="section-header">BCC:</div>
      <div className="section-content">{contentByType(data)[type]}</div>
    </div>

    <div className="section">
      <div className="section-header">Subject:</div>
      <div className="section-content">{data.subject}</div>
    </div>

    <div className="review-container">
      {/* When rendering the 'review' an error arises:
      <html> cannot appear as a child of <div>.
      which is "ok" since is just for reviewing the HTML
    */}
      <div className="review">{review}</div>
      <div className="note">
        Salutations will always be customized to the name of the individual user
      </div>
    </div>

    <CheckBox
      onClick={sendCopyHandler}
      defaultChecked={sendCopyRef.current}
      label="Send me a copy of this email for reference"
    />

    <div className="buttons-container">
      <Button
        size={"small"}
        onClick={onCloseModal}
        state={"link"}
        label="Cancel"
        color="primary"
        className="cancel-button"
      />
      <Button
        onClick={() => reviewHandler(null)}
        size={"small"}
        state={"secondary"}
        label="Back to Compose"
        color="primary"
        rightIcon={MdEdit}
      />
      <Button
        onClick={submitEmail}
        size={"small"}
        state={"primary"}
        label="Send"
        rightIcon={UpArrowIcon}
        color="primary"
      />
    </div>
  </div>
);
