import { FC } from "react";

import { RenderStringType } from "./types";
import { NoValue } from "../NoValue";

import "./scss/index.scss";

export const RenderString: FC<RenderStringType> = ({ value, properties }) => {
  if (!value) return <NoValue />;
  const { className: newClass } = properties ?? {};

  const className: string = `table-cell-string ${newClass ?? ""}`;

  return <div className={className}>{value}</div>;
};
