import { FC } from "react";

import { PaginationRangeProps } from "./types";

import "../scss/index.scss";

export const PaginationRange: FC<PaginationRangeProps> = ({
  DOTS,
  paginationRange,
  currentPage,
  onSelect,
}) => (
  <>
    {paginationRange.map((pageNumber: number | string) => {
      // If the pageItem is a DOT, render the DOTS unicode character
      if (pageNumber === DOTS)
        return (
          <li
            key={currentPage + Math.random()}
            className="pagination-item dots"
          >
            &#8230;
          </li>
        );

      // Render our Page Pills
      return (
        <li
          key={pageNumber}
          className={`pagination-item ${
            pageNumber === currentPage ? "selected" : undefined
          }`}
          onClick={() => onSelect(pageNumber as number)}
        >
          <button className="pagination-item-label">{pageNumber}</button>
        </li>
      );
    })}
  </>
);
