import { useContext, useState } from "react";

import { TableContext } from "@/components/Table/context";
import { useDetectClickOutside } from "@/components/Table/hooks/useDetectClickOutside";
import {
  FiltersType,
  TableFilters,
} from "@/components/Table/types/TableFilters";

export const useFiltersContainerLogic = () => {
  const dataDefaultValues = {
    header: { value: "", title: "" },
    subHeader: { value: "", title: "" },
  };
  const [data, setData] = useState<FiltersType>(dataDefaultValues);

  const resetData = () => {
    setData(dataDefaultValues);
  };

  const dataHandler = (value: FiltersType) => setData(value);

  const { filters, updateFilters } = useContext(TableContext);

  //Use config to get type of dropdown -- Single or search type
  const { options, config }: TableFilters = filters ?? {
    options: {},
  };

  let filterByOptions = Object.entries(options)
    .filter(([, { type }]) => type !== "date")
    .map(([key, value]) => ({
      title: value.label,
      value: key,
    }));

  const subHeaderOptions = data.header.value
    ? options[data.header.value]?.values
    : [];

  const { clickedOutside, clickedOutsideToggle, ref } =
    useDetectClickOutside(resetData);

  const toggleOpenFilter = () => clickedOutsideToggle();

  const oncloseModal = () => {
    toggleOpenFilter();
    resetData();
  };

  const submitFilters = () => {
    updateFilters?.(data);
    oncloseModal();
  };

  return {
    data,
    submitFilters,
    oncloseModal,
    filterByOptions,
    subHeaderOptions,
    ref,
    toggleOpenFilter,
    clickedOutside,
    dataHandler,
    config,
  };
};
