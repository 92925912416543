import { FC } from "react";

import { UserDetailsListProps } from "./types";
import { getUserDetailList } from "./util/getUserDetailList";

import "./scss/index.scss";

export const UserDetailsList: FC<UserDetailsListProps> = ({ options }) => (
  <ul className="collapse-container__list">
    {Object.entries(options).map(([key, value]) => (
      <li key={key} className="collapse-container__list__item">
        <span className="collapse-container__list__item__header">{key}</span>{" "}
        {value ? (
          getUserDetailList(key, value)
        ) : (
          <span className="collapse-container__list__item__content--not-listed">
            not listed
          </span>
        )}
      </li>
    ))}
  </ul>
);
