import { AlertActionsProps } from "./types";
import { AlertAction } from "@/components/Alert/types";

import "./scss/index.scss";

export const AlertActions = ({ color, actions }: AlertActionsProps) => {
  if (!actions) return null;

  return (
    <div className={`alert-actions-container ${color}`}>
      {(actions.filter((action) => action) as AlertAction[]).map(
        ({ label, onClick }: AlertAction) => (
          <button
            key={label}
            onClick={onClick}
            className={`alert-button ${color}`}
          >
            {label}
          </button>
        )
      )}
    </div>
  );
};
