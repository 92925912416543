export const validateDate=
  (handleInvalidState: (value: boolean) => void, isInvalid: boolean) =>
  (date: string): boolean => {
    if (isInvalid) handleInvalidState(false); // Reset invalid state when entered a valid date
    const anyNaNInArray: boolean = date
      .split("/")
      .some((value) => isNaN(Number(value)));

    if (anyNaNInArray) return false;

    const newDate: Date = new Date(date);

    if (newDate.toString() === "Invalid Date") {
      handleInvalidState(true);
      return false;
    }

    if (newDate > new Date()) {
      handleInvalidState(true);
      return false;
    }

    return true;
  };
