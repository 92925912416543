type Emails = {
  emails: string[];
  dataHandler: (value: string[]) => void;
  errorHandler: (error: string) => void;
};

export const useEmails = ({ emails, dataHandler, errorHandler }: Emails) => {
  const emailsHandler = (email: string) => {
    if (emails.includes(email)) {
      const errorMessage: string = `${email} is already in the list`;
      return errorHandler(errorMessage);
    }

    dataHandler([...emails, email]);
  };

  const removeEmail = (emailToRemove: string) => {
    const filteredEmailList: string[] = emails.filter(
      (email: string) => email !== emailToRemove
    );
    const isEmailListEmpty: boolean = !filteredEmailList.length;

    if (isEmailListEmpty) {
      const errorMessage: string = "At least one email required";
      errorHandler(errorMessage);
    }

    dataHandler(filteredEmailList);
  };

  return { emailsHandler, removeEmail };
};
