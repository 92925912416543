import { FC } from "react";

import { getURL } from "@/utils/getURL";
import { Table } from "@/components/Table";
import { useFilters } from "@/hooks/useFilters";
import { BlockIcon } from "../assets";

const url: string = getURL("api:internal-packages-online");
const filtersURL: string = getURL("api:internal-packages-schema-options");

export const OnlinePackages: FC = () => {
  const filterOptions = useFilters(filtersURL,
      ["buyer", "is_private", "shared_with", "pkg_status"]);

  return (
    <Table
      id={"id"}
      sortBy="-last_modified"
      navbar={{ header: "Online Packages", input: true }}
      headers={[
        { title: "#", apiParam: "id" },
        { title: "MODIFIED", apiParam: "last_modified", type: "date" },
        { title: "SELLER", apiParam: "seller" },
        { title: "ITEMS", apiParam: "component_string" },
        { title: "LISTED FOR", apiParam: "listed_for" },
        { title: "AUDIENCE", apiParam: "audience" },
        {
          title: "BLOCKED",
          apiParam: "has_block",
          type: "boolean",
          properties: {
            booleanType: {
              true: <BlockIcon />,
            },
          },
        },
      ]}
      filters={filterOptions}
      url={url}
    />
  );
};
