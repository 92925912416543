import { DropdownOptionTitle } from "@/components/DropdownMenu/types";

export const compareValues = <T>(
  valueA?: T,
  valueB?: DropdownOptionTitle
) => {
  if (valueA && valueB) return valueA === valueB;

  return false;
};
