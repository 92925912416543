import { FC } from "react";

import { SubTabsType } from "./types";
import { useCurrentTab } from "../../hooks/useActiveTab";
import { slugify } from "@/utils/slugify";

import "./scss/index.scss";

export const SubTabs: FC<SubTabsType> = ({
  subTabs,
  activeSubTab,
  isTabActive,
}) => {
  const { currentTab, handleActiveTab } = useCurrentTab(activeSubTab);
  const className = (title: string) =>
    `sub-tab-item ${
      currentTab === slugify(title) && isTabActive ? "active" : ""
    }`;

  return (
    <div className="sub-tabs-container">
      <ul className="sub-tabs-list">
        {subTabs.map(({ title, url }) => (
          <a key={title || url} className="link" href={url}>
            <li
              className={className(title)}
              onClick={() => handleActiveTab(title)}
            >
              {title}
            </li>
          </a>
        ))}
      </ul>
    </div>
  );
};
