import { FC, useContext } from "react";

import { TableContext } from "@/components/Table/context";
import { TableNavbarHeaderProps } from "./types";
import { useSearchParams } from "@/hooks/useSearchParams";

export const TableNavbarHeader: FC<TableNavbarHeaderProps> = () => {
  const { navbar, allRecordsCount } = useContext(TableContext);
  const className: string = "table-navbar__header-container";
  const { searchParams } = useSearchParams();

  const input: string | null = searchParams.get("q");

  return (
    <>
      <div className={className}>
        <div>
          {input ? (
            <span className="header">Search Results </span>
          ) : navbar?.header ? (
            <span className="header">{navbar.header} </span>
          ) : null}

          <span className={`results ${input ? "visible" : ""}`}>
            <strong>{allRecordsCount}</strong>{" "}
            {allRecordsCount === 1 ? "result" : "results"} found for{" "}
            <strong>{input}</strong>{" "}
            {navbar?.header ? `in ${navbar.header}` : ""}
          </span>
        </div>
      </div>
    </>
  );
};
