import { NoValue } from "./components/NoValue";
import { GetTableCellValueProps } from "./types";
import { getCellValue } from "./utils/getCellValue";

export const getTableCellValue = ({
  record,
  header,
  type,
  properties,
}: GetTableCellValueProps): JSX.Element => {
  const value = getCellValue({
    record,
    header,
    type,
    properties,
  });
  return value || <NoValue />;
};
