import { EditFacility } from "./components/EditFacility";
import { Impersonate } from "./components/Impersonate";
import { SubmitRecall } from "./components/SubmitRecall";
import { SubmitForm } from "./components/SubmitForm";
import { ViewProfile } from "./components/ViewProfile";
import { EditUserPermissions } from "./components/EditUserPermissions";
import { Email } from "./components/Email";
import { ActionsMap, GetActionProps } from "./types";

export const GetAction = <T extends Record<string, any>>({
  action,
  record,
  config,
}: GetActionProps<T>): JSX.Element => {
  const recordID: number = record.id;

  const actions: ActionsMap = {
    submit_recall: <SubmitRecall {...{ recordID }} />,
    submit_disposition_form: <SubmitForm {...{ recordID }} />,
    view_profile: <ViewProfile {...{ recordID }} />,
    edit_facility: <EditFacility {...{ recordID: record.facility_id }} />,
    impersonate: <Impersonate {...{ recordID }} />,
    edit_user_permissions: <EditUserPermissions {...{ record }} />,
    email: (
      <Email
        {...{
          record,
          config,
        }}
      />
    ),
  };

  return actions[action];
};
