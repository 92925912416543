import { FC } from "react";

import { getURL } from "@/utils/getURL";
import { Table } from "@/components/Table";
import { useFilters } from "@/hooks/useFilters";

const url: string = getURL("api:internal-open-orders-fulfilled");
const filtersURL: string = getURL("api:internal-open-orders-schema-options");

export const FulfilledOpenOrders: FC = () => {
  const filterOptions = useFilters(filtersURL, [
    "has_block",
    "is_direct",
    "shared_with",
    "state",
  ]);

  return (
    <Table
      id={"id"}
      sortBy="-last_modified"
      navbar={{ header: "Fulfilled Open Orders", input: true }}
      headers={[
        { title: "#", apiParam: "id" },
        { title: "LAST MODIFIED", apiParam: "last_modified", type: "date" },
        { title: "BUYER", apiParam: "buyer" },
        { title: "SELLER", apiParam: "seller" },
        { title: "ITEMS", apiParam: "component_string" },
        { title: "LISTED FOR", apiParam: "expire_after" },
        { title: "AUDIENCE", apiParam: "audience" },
      ]}
      filters={filterOptions}
      url={url}
    />
  );
};
