import { toast } from "react-toastify";
import Cookies from "universal-cookie";

import { getURL } from "@/utils/getURL";
import { SendEmailProps } from "./types";
import { getBody } from "../getBody";

const cookies = new Cookies();

export const sendEmail = ({
  props,
  data,
  sendCopy,
}: SendEmailProps) => {
  const id = toast.loading("Uploading email");
  const url: string = getURL("api:custom-email-message-list");
  const autoCloseTime: number = 3000; //3 seconds
  props.onCloseModal();
  props.checkboxesHandler([]);

  (async () => {
    try {
      const fetchResponse: Response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": cookies.get("csrftoken"),
          "X-Requested-With": "XMLHttpRequest",
        },
        body: JSON.stringify({
          ...data,
          ...getBody({ sendCopy, data }),
        }),
      });

      fetchResponse.ok
        ? toast.update(id, {
            render: "Email sent",
            type: "success",
            isLoading: false,
            autoClose: autoCloseTime,
            hideProgressBar: true,
            theme: "dark",
          })
        : toast.update(id, {
            render: "Email wasn't sent",
            type: "error",
            isLoading: false,
            autoClose: autoCloseTime,
            hideProgressBar: true,
            theme: "dark",
          });
    } catch (error) {
      console.error(error);
      toast.update(id, {
        render: "Email wasn't sent",
        type: "error",
        isLoading: false,
        autoClose: autoCloseTime,
        hideProgressBar: true,
        theme: "dark",
      });
    }
  })();
};
