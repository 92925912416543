import { FC } from "react";

import { UpperTabsProps } from "./types/index";
import { useActiveTab } from "./hooks/useActiveTab";
import { slugify } from "@/utils/slugify";

import "./scss/index.scss";

export const UpperTabs: FC<UpperTabsProps> = ({
  tabs,
  handleActiveTab,
  activeTab,
}) => {
  const { currentTab } = useActiveTab(activeTab, handleActiveTab);

  const className = (title: string): string =>
    `upper-tab-item ${slugify(currentTab) === slugify(title) ? "active" : ""}`;

  return (
    <div className="upper-tabs-container">
      {tabs.map(({ title, subTabs }) => {
        const url: string = subTabs ? subTabs[0].url : "#";
        return (
          <a href={url} key={title} className={className(title)}>
            {title}
          </a>
        );
      })}
    </div>
  );
};
