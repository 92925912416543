import { OnClickProps } from "./type";

export const onClick = ({
  resetExpandState,
  headerApiParam,
  updateSearchParams,
  sortBy,
}: OnClickProps) => {
  resetExpandState?.();

  if (sortBy === headerApiParam)
    return sortBy[0] === "-"
      ? updateSearchParams?.("sort_by", headerApiParam)
      : updateSearchParams?.("sort_by", `-${headerApiParam}`);

  updateSearchParams?.("sort_by", headerApiParam);
};
