import { FC } from "react";

import { DropdownMenu } from "@/components/DropdownMenu";
import { PaginationPageSizeOptionsProps } from "./types";

import "./scss/index.scss";

/**
 * PageSizeOptions is a functional component that renders a container with page size options.
 *
 * @param pageSizeOptions An array of numbers representing the available page size options.
 * @param pageSize The current selected page size.
 * @param pageSizeHandler A function that handles the changing of the page size.
 */
export const PaginationPageSizeOptions: FC<PaginationPageSizeOptionsProps> = ({
  pageSizeOptions,
  pageSize,
  pageSizeHandler,
}) => {
  const dropdownMenuOptions = pageSizeOptions.map((option: number) => ({
    title: option,
    onClick: () => pageSizeHandler(option),
  }));

  return (
    <div className="pagination-options-container">
      <DropdownMenu selectedOption={pageSize} options={dropdownMenuOptions} />
    </div>
  );
};
