import { FC } from "react";

import { IDType } from "../types";

/**
 * Link for impersonating an user
 *
 * @param ID - User ID
 */
export const Impersonate: FC<IDType> = ({ recordID: userID }) => (
  <a href={`/impersonate/${userID}/`}>Impersonate</a>
);
