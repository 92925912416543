import { getTableCellValue } from "./utils/getTableCellValue";
import { TableRowHeadersProps } from "./types";

import "./scss/index.scss";

export const TableRowHeaders = ({
  rowHeaders,
  record,
}: TableRowHeadersProps) => {
  return rowHeaders.map(({ apiParam: header, ...props }, index) => (
    <td key={index} className="table-row__cell">
      <div className={`table-row__header`}>
        {getTableCellValue({ record, header, ...props })}
        {header === "name" && record["is_primary_contact"] && (
          <span className="table-row__tag">PRI</span>
        )}
      </div>
    </td>
  ));
};
