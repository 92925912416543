import { useState } from "react";

import { slugify } from "@/utils/slugify";

export const useCurrentTab = (tab: string) => {
  const [currentTab, setCurrentTab] = useState<string>(tab);

  const handleActiveTab = (value: string) => {
    setCurrentTab(slugify(value));
  };

  return { currentTab, handleActiveTab };
};
