import { FC } from "react";
import moment from "moment";

import { RenderDateType } from "./types";
import { NoValue } from "../NoValue";

export const RenderDate: FC<RenderDateType> = ({ value, properties }) => {
  const { className, format } = properties ?? {};
  const defaultFormat: string = "L";

  if (!value) return <NoValue />;

  return (
    <div className={className ?? ""}>
      {moment(value).format(format || defaultFormat)}
    </div>
  );
};
