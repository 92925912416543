export const recordsIDSerializer = <T extends Record<string, any>>(
  id: string,
  results?: T[] | null
): T[] => {
  if (!results) return [];

  const serializedArray: T[] = results?.map((record: T) => {
    const { [id]: oldID, ...rest } = record;
    return {
      id: record[id],
      ...rest,
    } as unknown as T;
  });

  return serializedArray;
};
