import { useContext, useEffect, useRef, useState } from "react";

import { TableContext } from "@/components/Table/context";
import { getLast30Days } from "./utils/getLast30Days";
import { getLast3Months } from "./utils/getLast3Months";
import { getLast7Days } from "./utils/getLast7days";
import { getLast90Days } from "./utils/getLast90Days";
import { getLastMonth } from "./utils/getLastMonth";
import { DateRangeOptions } from "../types";
import { DateFilterOptions } from "./types";
import { useSearchParams } from "@/hooks/useSearchParams";
import { formatDate } from "@/components/Table/hooks/useFetch/utils/buildFetchURL/utils/formatDate";
import { DEFAULT_DROPDOWN_HEADER } from "../constants";

export const useDatePickerLogic = () => {
  const { filters, updateSearchParams } = useContext(TableContext);
  const { searchParams } = useSearchParams();
  const changeMonthRef = useRef<(value: number) => void>();
  const changeYearRef = useRef<(value: number) => void>();

  const startDate: string = searchParams.get("start_date") || "";
  const endDate: string = searchParams.get("end_date") || "";
  const dateField: string = searchParams.get("date_field") || "";

  const defaultDateRange: string = startDate && endDate ? "Custom Range" : "";
  const [dateRange, setDateRange] = useState<string>(defaultDateRange);

  const handleUpdateSelectedDateRange = (value: string) => {
    const notRepeatableDateRange = "Custom Range";

    if (
      dateRange === notRepeatableDateRange &&
      value === notRepeatableDateRange
    )
      return;

    setDateRange(value);
  };

  const handleUpdateDateRange = ({
    startDate,
    endDate,
  }: {
    startDate: Date | null;
    endDate: Date | null;
  }) => {
    updateSearchParams?.("start_date", formatDate(startDate));
    updateSearchParams?.("end_date", formatDate(endDate));
  };

  const dateFilterOptions: DateFilterOptions = Object.entries(filters?.options!)
    .filter(([, value]) => value.type === "date")
    .map(([key, value]) => ({ title: value.label, value: key }));

  const defaultDateFieldTitle: string = dateFilterOptions[0].title;
  const defaultDateFieldValue: string = dateFilterOptions[0].value;

  const { allowFutureDates } = filters ?? {};

  useEffect(() => {
    if (!dateField) {
      updateSearchParams?.("date_field", defaultDateFieldValue);
    }
  }, [dateField, defaultDateFieldValue, updateSearchParams]);

  const dateRangeOptions: DateRangeOptions[] = [
    ["Last 7 days", getLast7Days],
    ["Last 30 days", getLast30Days],
    ["Last month", getLastMonth],
    ["Last 90 days", getLast90Days],
    ["Last 3 months", getLast3Months],
    ["Custom Range", () => handleUpdateSelectedDateRange("Custom Range")],
    [
      "All time",
      () => {
        handleUpdateSelectedDateRange(DEFAULT_DROPDOWN_HEADER);
        handleUpdateDateRange({
          startDate: null,
          endDate: null,
        });
      },
    ],
  ];

  return {
    changeMonthRef,
    startDate,
    endDate,
    changeYearRef,
    dateRangeOptions,
    handleUpdateSelectedDateRange,
    handleUpdateDateRange,
    dateRange,
    dateFilterOptions,
    defaultDateFieldTitle,
    updateSearchParams,
    dateField,
    allowFutureDates,
  };
};
