import { useState } from "react";

export const useError = () => {
  const [error, setError] = useState<string>("");

  const errorHandler = (error: string) => {
    setError(error);
  };

  const resetErrorMessage = () => {
    if (error) errorHandler("");
  };

  return { error, errorHandler, resetErrorMessage };
};
