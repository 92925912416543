import { FacilityDetails } from "../FacilityDetails";
import { FacilityDetailsProps } from "../FacilityDetails/types";
import { UserDetails } from "../UserDetails";
import { UserDetailsProps } from "../UserDetails/types";
import { SuggestionsProps } from "./types";

export const Suggestions = ({
  type,
  inputRef,
  suggestions,
  emailsHandler,
  suggestionsHandler,
  facilityHandler,
  displayErrorHandler,
}: SuggestionsProps) => {
  const detailsToRender = (record: Record<string, any>) =>
    ({
      user: <UserDetails {...({ record } as UserDetailsProps)} />,
      facility: <FacilityDetails {...({ record } as FacilityDetailsProps)} />,
    })[type];

  const isAnyInput: boolean = !!inputRef.current?.value.length;
  const anySuggestions: boolean = !!suggestions.length;

  const onClick = (record: Record<string, any>) => {
    if (type === "user") {
      const email: string = `${record.full_name} <${record.email}>`;
      emailsHandler(email);
    }
    if (type === "facility") facilityHandler(record);
    suggestionsHandler([]);
    displayErrorHandler(false);
    inputRef.current && (inputRef.current.value = "");
  };

  return isAnyInput ? (
    <ul className="list-element drop-shadow-x-large">
      {anySuggestions ? (
        suggestions.map((suggestion: Record<string, any>, index: number) => (
          <li
            onClick={() => onClick(suggestion)}
            className="li-element"
            key={index}
          >
            {detailsToRender(suggestion)}
          </li>
        ))
      ) : (
        <li className="li-element no-suggestions">No suggestions</li>
      )}
    </ul>
  ) : null;
};
