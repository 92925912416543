import { DropDownSingleSelectProps } from "../DropdownSingleSelect/types";
import { useDropdownSingleSelectSearchableLogic } from "./hooks/useDropdownSingleSelectSearchableLogic";
import { DropdownSingleSelectSearchableIcon } from "./components/DropdownSingleSelectSearchableIcon";
import { FilteredDropdownMenu } from "./components/FilteredDropdownMenu";
import { SelectedOptionType } from "../DropdownMenu/types";

import "./scss/index.scss";

export const DropdownSingleSelectSearchable = <T,>({
  options,
  header,
  selectedOption,
  reopenOnOptionClick,
  ...dropdownMenuProps
}: DropDownSingleSelectProps<T>) => {
  const {
    filteredOptions,
    isAnyFilteredOption,
    inputValue,
    open,
    resetDropdownContent,
    onFocus,
    debouncedOnChange,
    inputRef,
  } = useDropdownSingleSelectSearchableLogic(options, reopenOnOptionClick);

  const onClick = ({ value, title }: SelectedOptionType<T>) => {
    dropdownMenuProps?.onClick?.({ value, title });
    resetDropdownContent();
  };

  const thereAreNoOptions: boolean = !options.length;

  const placeholder: string | undefined | number = thereAreNoOptions
    ? "No options"
    : selectedOption?.toString() || "Search...";

  return (
    <div>
      <strong>{header}</strong>
      <div className="single-select-searchable-dropdown">
        <input
          ref={inputRef}
          className="input"
          data-testid="input"
          type={"text"}
          placeholder={placeholder}
          onChange={({ target: { value } }) => debouncedOnChange(value)}
          onFocus={onFocus}
        />
        <DropdownSingleSelectSearchableIcon
          {...{ resetDropdownContent, inputValue }}
        />
        {thereAreNoOptions ? null : (
          <FilteredDropdownMenu
            {...{
              open,
              isAnyFilteredOption,
              filteredOptions,
              dropdownMenuProps,
              onClick,
              selectedOption,
            }}
          />
        )}
      </div>
    </div>
  );
};
