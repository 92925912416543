import { FC } from "react";

import { NoPermissionsBullet } from "./components/NoPermissionBullet";
import { PermissionsBullet } from "./components/PermissionBullet";

type PermissionType = {
  name: string;
  permissions: { name: string; id: number }[];
  short_name: string;
};

export const Permission: FC<PermissionType> = ({
  name,
  permissions,
  short_name,
}) => (
  <ul className="bullets-container">
    {name} (<strong>{short_name}</strong>)
    {permissions.length ? (
      <PermissionsBullet {...{ permissions }} />
    ) : (
      <NoPermissionsBullet />
    )}
  </ul>
);
