import { RenderBoolean } from "../components/RenderBoolean";
import { RenderDate } from "../components/RenderDate";
import { RenderBadge } from "../components/RenderBadge";
import { RenderNumber } from "../components/RenderNumber";
import { RenderString } from "../components/RenderString";
import { RenderURL } from "../components/RenderURL";
import { RenderArray } from "../components/RenderArray";
import { GetCellValueProps } from "./types";

export const getCellValue = ({
  record,
  header,
  type,
  properties,
}: GetCellValueProps) => {
  // Destructuring the header into headerKey and subKey
  const [headerKey, subKey] = Array.isArray(header)
    ? header
    : [header, undefined];

  // Retrieving the record value based on the header and subKey
  const value = subKey ? record[headerKey]?.[subKey] : record[headerKey];

  const valueType: Record<string, JSX.Element> = {
    string: <RenderString {...{ value, headerKey, properties }} />,
    boolean: <RenderBoolean {...{ value, properties }} />,
    number: <RenderNumber {...{ value, properties }} />,
    url: <RenderURL {...{ value, properties }} />,
    badge: <RenderBadge {...{ value, headerKey, properties }} />,
    date: <RenderDate {...{ value, properties }} />,
  };

  if (Array.isArray(value)) return <RenderArray {...{ value, properties }} />;

  return valueType[type || typeof value];
};
