import { UserDetailsProps } from "./types";

import "./scss/index.scss";

export const UserDetails = ({ record: user }: UserDetailsProps) => (
  <div className="user-details-container">
    <div className="user-metadata">
      {user.full_name} {`<${user.email}>`}
    </div>
    <div className="user-sub-metadata">{user.facility.name}</div>
  </div>
);
