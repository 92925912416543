import { BuildFetchURLProps } from "./types";

/**
 * Builds the URL for the fetch call.
 *
 * @param pageSize - The current page size.
 * @param currentPage - Current page to fetch the data.
 * @param input - Input value to filter the API response.
 * @param url - URL to fetch data from.
 * @returns The built URL.
 */
export const buildFetchUrl = ({
  url,
  pageSize,
  page,
  input,
  filters,
  dateStatus,
  startDate,
  endDate,
  sortBy,
}: BuildFetchURLProps) => {
  const offset: number = (page - 1) * pageSize;
  const date: string = `${dateStatus}_after=${startDate}&${dateStatus}_before=${endDate}`;
  const currentFilters: string | undefined = filters
    ?.map(
      ({ header, subHeader: { value } }) =>
        `${encodeURIComponent(header.value.toLowerCase())}=${encodeURIComponent(
          value
        )}`
    )
    .join("&");

  const queryParams: string = `?limit=${pageSize}&offset=${offset}&search=${encodeURIComponent(
    input
  )}&ordering=${sortBy}${currentFilters ? `&${currentFilters}` : ""}&${
    startDate ? date : ""
  }`;

  return url + queryParams;
};
