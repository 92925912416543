import { FC } from "react";

import { IDType } from "../types";

/**
 * Modal for the creation of disposition forms
 *
 * @param ID - Facility ID
 */
export const SubmitForm: FC<IDType> = ({ recordID: facilityID }) => (
  <a
    className="js-get_file_upload_modal"
    href="#"
    data-url="/accounts/disposition-form/"
    data-source_profile_pk={facilityID}
  >
    Submit a Disposition Form
  </a>
);
