import { FC } from "react";

import { IDType } from "../types";

/**
 * Link to the facility profile page
 *
 * @param ID - Facility ID
 */
export const ViewProfile: FC<IDType> = ({ recordID: facilityID }) => (
  <a
    className="js-anchor_link"
    href={`/accounts/facility-detail/${facilityID}/`}
  >
    View Profile
  </a>
);
