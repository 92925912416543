import { useState } from "react";

export const useActiveTab = (
  activeTabDefault: string,
  handleActiveTab: (tab: string) => void
) => {
  const [currentTab, setCurrentTab] = useState<string>(activeTabDefault);

  const handleTabClick = (tab: string) => {
    handleActiveTab(tab);
    return setCurrentTab(tab);
  };

  return { currentTab, handleTabClick };
};
