/**
 * Validates the response from the API.
 *
 * @param response - The response object from the API.
 * @param httpStatusCode - The HTTP status code to consider as an error.
 * @throws An error if the response is not OK or the status code is greater than or equal to the provided HTTP status code.
 */
export const validateResponse = async (
  responseBody: any,
  response: Response
) => {
  const httpErrorStatusCode: number = 400;
  const responseStatusCode: number = response.status;

  if (responseStatusCode < httpErrorStatusCode && response.ok) return;

  const defaultErrorMessage: string =
    "We couldn't fetch your data, please try again later";

  const error: string = responseBody.error || defaultErrorMessage;

  console.error(response.statusText);
  throw new Error(error);
};
