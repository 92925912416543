import { FC } from "react";

import { getURL } from "@/utils/getURL";
import { Table } from "@/components/Table";
import { useFilters } from "@/hooks/useFilters";

const url: string = getURL("api:internal-orders-canceled");
const filtersURL: string = getURL("api:internal-orders-schema-options");

export const CanceledOrders: FC = () => {
  const filterOptions = useFilters(filtersURL, [
    "in_transit_at",
    "received_at",
    "order_status",
  ]);

  return (
    <Table
      id={"facility_id"}
      sortBy="-canceled_at"
      navbar={{ header: "Canceled Orders", input: true }}
      headers={[
        { title: "#", apiParam: "id" },
        { title: "PLACED ON", apiParam: "ordered_at", type: "date" },
        { title: "CANCELED ON", apiParam: "canceled_at", type: "date" },
        { title: "TYPE", apiParam: "order_type" },
        { title: "ITEMS", apiParam: "component_string" },
        { title: "BUYER", apiParam: "buyer" },
        { title: "SELLER", apiParam: "seller" },
        { title: "AUDIENCE", apiParam: "audience" },
      ]}
      filters={filterOptions}
      url={url}
    />
  );
};
