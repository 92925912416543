import { IconColor } from "@/components/Icon/types";
import { onClickHandler } from "./onClickHandler";
import { BadgeLogic, BadgeLogicProps } from "./types";

export const badgeLogic = ({
  size,
  state,
  color,
  disabled,
  appendedClass,
  callback,
  label,
}: BadgeLogicProps): BadgeLogic => {
  const className: string = `react-badge ${size} ${state}-${color} ${
    disabled ? "disabled" : ""
  } ${appendedClass} ${callback ? "clickable" : ""}`;

  const onClick = onClickHandler(label, callback);

  const iconColor: IconColor | undefined =
    state === "primary" ? undefined : color;

  return {
    className,
    onClick,
    iconColor,
  };
};
