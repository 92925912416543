import { useState } from "react";
import { AiOutlineExclamationCircle } from "react-icons/ai";

import { Button } from "@/components/Button";
import { ModalTemplateProps } from "@/components/Table/hooks/useModal/types";
import { useFetch } from "@/components/Table/hooks/useFetch";
import { CheckBox } from "@/components/CheckBox";
import { getURL } from "@/utils/getURL";
import { Alert } from "@/components/Alert";
import { updatePermissions } from "./utils/updatePermissions";
import { submitPermissions } from "./utils/submitPermissions";

export const EditUserPermissionsModal = <T extends Record<string, any>>({
  record: { first_name, last_name, permission_groups, id: user_id },
  onCloseModal,
  updateRecord,
}: ModalTemplateProps) => {
  const url: string = getURL("api:groups-list");
  const { results: listOfPermissions } = useFetch<T>({ url });
  const [permissions, setPermissions] = useState<T[]>(permission_groups);

  const submitIDs = submitPermissions({
    user_id,
    permissions,
    updateRecord,
    onCloseModal,
  });
  const isPermissionsEmpty: boolean = !permissions.length;

  const isPermissionInsideList = (record: T) =>
    permissions.map((permission) => permission.id).includes(record.id);

  return (
    <div className="modal-container">
      {isPermissionsEmpty ? (
        <Alert
          icon={AiOutlineExclamationCircle}
          description={"At least one permission is required"}
          color={"destructive"}
        />
      ) : null}
      <h4 className="header">Edit User Permissions</h4>
      <br />
      <p>
        You are editing the user permissions for{" "}
        <strong>
          {first_name} {last_name}
        </strong>
        . Please select the permissions applicable. User may belong to more than
        1 permission group.
      </p>
      <br />
      <div className="bullets-container">
        {listOfPermissions?.map((record: T) => (
          <CheckBox
            key={record.id}
            defaultChecked={isPermissionInsideList(record)}
            onClick={() => updatePermissions(setPermissions)(record)}
            label={record.name}
          />
        ))}
      </div>
      <div className="buttons-container">
        <Button
          onClick={onCloseModal}
          size={"medium"}
          color={"primary"}
          state={"link"}
          label={"Cancel"}
        />
        <Button
          onClick={submitIDs}
          size={"medium"}
          color={"primary"}
          state={"primary"}
          label={"Save Changes"}
          disabled={isPermissionsEmpty}
        />
      </div>
    </div>
  );
};
