import { useState } from "react";

import { UseVisible } from "./types";

export const useVisible = (): UseVisible => {
  const [visible, setVisible] = useState<boolean>(true);
  const toggleVisible = () => setVisible(!visible);

  return {visible,  toggleVisible };
};
