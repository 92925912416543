import { useContext, useRef, useState } from "react";
import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";

import { TableContext } from "@/components/Table/context";
import { ModalTemplateType, UseModalProps } from "./types";

import "./scss/index.scss";

/**
 * A custom hook that returns an object with a modal component and a function to open the modal.
 *
 * @param ModalTemplate - A React component that represents the content of the modal.
 * @param props - An object with props to pass to the `ModalTemplate` component.
 *
 * @returns An object with two properties:
 *   - `modal`: A JSX element representing the modal component.
 *   - `onOpenModal`: A function to open the modal.
 */
export const useModal = (
  ModalTemplate: ModalTemplateType,
  props?: object
): UseModalProps => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const config = useRef(props);
  const context = useContext(TableContext);

  const openModal = (props?: object) => {
    config.current = Object.assign(config.current ?? {}, props ?? {});
    setIsOpen(true);
  };

  const closeModal = () => setIsOpen(false);

  return {
    modal: isOpen ? (
      <Modal
        focusTrapped={false}
        showCloseIcon={true}
        open={isOpen}
        onClose={closeModal}
        center
      >
        <ModalTemplate
          {...{
            onCloseModal: closeModal,
            ...context,
            ...config.current,
          }}
        />
      </Modal>
    ) : null,
    onOpenModal: openModal,
  };
};
