import { BsThreeDots } from "react-icons/bs";
import { MdOutlineMail } from "react-icons/md";
import { useContext } from "react";

import { Button } from "@/components/Button";
import { BulkActionsButtonsProps } from "./types";
import { TableContext } from "@/components/Table/context";

import "./scss/index.scss";

export const BulkActionsButtons = ({
  bulkActions,
}: BulkActionsButtonsProps) => {
  const { primaryAction, secondaryAction, more } = bulkActions ?? {};
  const {
    checkboxes,
    checkboxesHandler,
    bulkActions: actions,
  } = useContext(TableContext);

  return (
    <div className="bulk-actions-container">
      <Button
        label={primaryAction?.label || "Primary Action"}
        size={"small"}
        color={"primary"}
        state={"secondary"}
        leftIcon={MdOutlineMail}
        onClick={() =>
          primaryAction?.onClick({
            checkboxes,
            checkboxesHandler,
            bulkConfig: actions?.primaryAction.config,
          })
        }
      />
      {secondaryAction ? (
        <Button
          label={secondaryAction.label}
          size={"small"}
          color={"neutral"}
          state={"outlined"}
          onClick={() => secondaryAction.onClick()}
        />
      ) : null}
      {more ? (
        <Button
          label="More"
          rightIcon={BsThreeDots}
          size={"small"}
          color={"neutral"}
          state={"outlined"}
        />
      ) : null}
    </div>
  );
};
