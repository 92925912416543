import { FC } from "react";

import { RenderBooleanType, Properties } from "./types";
import { convertBooleanStringToNumber } from "./utils/convertBooleanStringToNumber";
import { Default } from "./components/Default";

export const RenderBoolean: FC<RenderBooleanType> = ({ value, properties }) => {
  const { className, booleanType }: Properties = properties ?? {};

  if (!booleanType) return <Default {...{ className, value }} />;

  const booleanTypeSerializer: Record<number, JSX.Element> =
    convertBooleanStringToNumber(booleanType);

  return <div className={className ?? ""}>{booleanTypeSerializer[+value]}</div>;
};
