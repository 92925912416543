import { FC } from "react";

import { PaginationInfoProps } from "./types";
import { PaginationPageSizeOptions } from "../components/PaginationPageSizeOptions";

/**
 * @returns the total count of records, and pagination size options
 *
 * @param totalCount - Total number of records
 * @param pageSizeOptions - Page size options to update the current page size
 * @param pageSizeHandler - Set the current page size
 * @param pageSize - The number of records to display per page
 */
export const PaginationInfo: FC<PaginationInfoProps> = ({
  totalCount,
  pageSizeOptions,
  pageSizeHandler,
  pageSize,
}) => (
  <div className="pagination-container">
    <div className="pagination-info">
      <span className="pagination-info__range">{totalCount} entries</span>
      <PaginationPageSizeOptions
        {...{ pageSizeOptions, pageSizeHandler, pageSize }}
      />
    </div>
  </div>
);
