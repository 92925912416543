import { useState } from "react";

import { useGetDropdownRef } from "../hook/useGetDropdownRef";
import { UseOpen } from "./types";

export const useOpen = (): UseOpen => {
  const [open, setOpen] = useState<boolean>(false);
  const toggleDropdown = () => setOpen(!open);
  const openHandler = (value: boolean) => setOpen(value);
  const dropdownRef = useGetDropdownRef([open, setOpen]);

  return { open, toggleDropdown, dropdownRef, openHandler };
};
