import {
  FiltersType,
  TableFiltersOptions,
} from "@/components/Table/types/TableFilters";
import { useSearchParams } from "@/hooks/useSearchParams";

/**
 * Get the active filters from the URL query params
 *
 * @param options
 * @returns
 */
export const useParam = (options: TableFiltersOptions) => {
  const { searchParams } = useSearchParams();
  let activeFilters: FiltersType[] = [];

  for (const [key, activeValue] of searchParams.entries()) {
    const option = options?.[key];

    // Check if the option exists
    if (!option) continue;

    const subHeader = option.values?.find(({ value }) => value.toString() === activeValue);

    // Check if subHeader exists
    if (!subHeader) continue;
    const header = { title: option.label, value: key };

    // Check if header exists
    if (!header) continue;
    activeFilters = [
      ...activeFilters,
      {
        header,
        subHeader,
      },
    ];
  }

  return activeFilters;
};
