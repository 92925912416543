import { useState } from "react";

import { DataType } from "./types";
import { EmailType } from "../../types";

export const useData = (
  props: Record<string, any> | undefined,
  type: EmailType
) => {
  const defaultUserEmail: string[] = props?.checkboxes?.length
    ? props.checkboxes
        .filter((user: any) => user.is_active)
        .map((user: Record<string, any>) => `${user.full_name} <${user.email}>`)
    : props?.is_active
    ? [`${props?.full_name} <${props?.email}>`]
    : [];

  const defaultFacilityEmail: string[] = props?.checkboxes?.length
    ? props.checkboxes
        .filter((facility: any) => facility.status === "Active")
        .map((facility: Record<string, any>) => facility.name)
    : props?.status === "Active"
    ? [props?.name]
    : [];

  const defaultFacilityID: string[] =
    type === "facility"
      ? props?.checkboxes?.length
        ? props.checkboxes
            .filter((facility: any) => facility.status === "Active")
            .map((facility: Record<string, any>) => facility.id)
        : props?.status === "Active"
        ? [props?.id]
        : []
      : [];

  const [data, setData] = useState<DataType>({
    "subject": "",
    "message": "",
    "email_addresses":
      type === "facility" ? defaultFacilityEmail : defaultUserEmail,
    "facility_uuids": defaultFacilityID,
  });

  const dataHandler = (key: string) => (value: string | string[]) => {
    setData((latestData) => ({ ...latestData, [key]: value }));
  };

  const isAnyEmail: boolean = !data.email_addresses.length;
  const isAnySubject: boolean = !data.subject.length;
  const isAnyMessage: boolean = !data.message.length;

  return {
    data,
    dataHandler,
    isAnyEmail,
    isAnySubject,
    isAnyMessage,
  };
};
