import { ChangeEvent } from "react";

import { getUrlByType } from "./utils/getUrlByType";
import { EmailType } from "../../../types";

export const onChange =
  (
    suggestionsHandler: (value: Record<string, any>[]) => void,
    type: EmailType
  ) =>
  async (event: ChangeEvent<HTMLInputElement>) => {
    try {
      const value: string = event.target.value;
      if (!value) return suggestionsHandler([]);

      const url: string = getUrlByType[type];
      if (!url) throw new Error("URL not found");
      const fetchResponse: Response = await fetch(url + `?search=${value}`);
      const { results } = await fetchResponse.json();

      suggestionsHandler(results);
    } catch (error) {
      console.error(error);
    }
  };
