import { FC } from "react";

import { RenderURLType } from "./types";

export const RenderURL: FC<RenderURLType> = ({ value, properties }) => {
  const { className } = properties ?? {};
  const title: string = typeof value === "string" ? value : value?.title;
  const url: string = typeof value === "string" ? value : value?.value;

  return (
    <a className={className ?? ""} href={url}>
      {title}
    </a>
  );
};
