import { RiErrorWarningFill } from "react-icons/ri";
import { MdOutlineDownloading } from "react-icons/md";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { InfoTable } from "./components/InfoTable";
import { TableNavbar } from "./components/TableNavbar";
import { BaseTable } from "./components/BaseTable/index";
import { Pagination } from "./components/BaseTable/components/Pagination";
import { TableFilters } from "./components/TableFilters";
import { BulkActionsButtons } from "./components/BulkActionsButtons";
import { TableProps } from "./types/Table";
import { TableContext } from "./context";
import { useTableLogic } from "./hooks/useTableLogic";
import { LoadingTable } from "./common/BaseTable/components/LoadingTable";
import { useSearchParams } from "@/hooks/useSearchParams";

import "./scss/index.scss";

/**
 * Renders a dynamic table component with various features like sorting, filtering, bulk actions, pagination, etc.
 *
 * @param {TableProps} props - Component props.
 * @param {string} props.id - Name of the ID property inside the records API response.
 * @param {TableNavbar} [props.navbar] - Conditional Navbar to be render on top of the table.
 * @param {TableFilters} [props.filters] - Options for table filters.
 * @param {TableDropDownProps} [props.dropDown] - Conditional drop down rows to render inside the table.
 * @param {TableHeaders} props.headers - Headers of the table.
 * @param {TableActions} [props.actions] - Conditional actions to render inside the table.
 * @param {string} props.url - URL (API) from which the table will be populated.
 * @param {boolean} [props.addRecord] - Flag to indicate if record addition is allowed.
 * @param {TableBulkActions} [props.bulkActions] - Conditional bulk actions to render inside the table.
 *
 * @returns {JSX.Element} The JSX element, Table component.
 *
 * @example
 * Simple usage:
 * ```
 * <Table<FacilityUserType>
 *  id={"user_id"}
 *  headers={[
 *  { title: "NAME", apiParam: "name" },
 *  { title: "EMAIL", apiParam: "email" },
 *  { title: "FACILITY", apiParam: "facility" },
 *  { title: "ROLE", apiParam: "role" },
 *  ]}
 *  url={facilityURL}
 * />
 * ```
 *
 * @example
 * With navbar:
 * ```
 * <Table<FacilityUserType>
 *  id={"user_id"}
 *  headers={[
 *  { title: "NAME", apiParam: "name" },
 *  { title: "EMAIL", apiParam: "email" },
 *  { title: "FACILITY", apiParam: "facility" },
 *  { title: "ROLE", apiParam: "role" },
 *  ]}
 * navbar={{
 *  navbarHeader: "Users",
 *  navbarTooltip: {
 *   type: "?", // options "?" | "!"
 *   message: "A list of all users in your facility.",
 *  },
 *  input: true,
 * }}
 *  url={facilityURL}
 * />
 * ```
 *
 * @example
 * With drop down:
 * ```
 * <Table<FacilityUserType>
 *  id={"user_id"}
 *  headers={[
 *   { title: "NAME", apiParam: "name" },
 *   { title: "EMAIL", apiParam: "email" },
 *   { title: "FACILITY", apiParam: "facility" },
 *   { title: "ROLE", apiParam: "role" },
 *  ]}
 *  dropDown={{
 *   template: UserDetails,
 *   props: { //props are conditional
 *    collapseRow: {
 *     id: "user_id",
 *     primaryMessage: "$name,",
 *     secondaryMessage: "$role at $facility.",
 *     options: {
 *      Email: "email",
 *      Phone: "formatted_office_number",
 *      Mobile: "formatted_cell_number",
 *     },
 *    },
 *   },
 *  }}
 *  url={facilityURL}
 * />
 *
 * ```
 *
 * @privateRemarks
 * {@link https://bloodbuy.atlassian.net/browse/BB-4293 - Jira Story}
 */
export const Table = <T extends object>({
  headers,
  url,
  id,
  bulkActions,
  sortBy,
  ...props
}: TableProps) => {
  const firstTableColumnParam = headers[0].apiParam;
  const sortByDefaultValue = Array.isArray(firstTableColumnParam)
    ? firstTableColumnParam[0]
    : firstTableColumnParam;

  const {
    error,
    results,
    updateRecord,
    updateFilters,
    toggleCheckbox,
    toggleAllCheckboxes,
    checkboxes,
    filters,
    setFilters,
    allRecordsCount,
    paginationSiblingCount,
    records,
    checkboxesHandler,
    areAllCheckboxesSelected,
    expandState,
    expandStateHandler,
    resetExpandState,
    apiParams,
    isTableLoading,
    page,
    pageSize,
  } = useTableLogic<T>({
    url,
    id,
    options: props.filters?.options,
    sortByDefaultValue: sortBy || sortByDefaultValue,
  });

  const { updateSearchParams } = useSearchParams();

  if (error)
    return <InfoTable primaryMessage={error} icon={RiErrorWarningFill} />;

  if (!results)
    return (
      <InfoTable primaryMessage="Loading..." icon={MdOutlineDownloading} />
    );

  return (
    <div className="table-container">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <TableContext.Provider
        value={{
          ...props,
          updateRecord,
          areAllCheckboxesSelected,
          records,
          updateFilters,
          toggleCheckbox,
          toggleAllCheckboxes,
          checkboxes,
          bulkActions,
          checkboxesHandler,
          expandState,
          expandStateHandler,
          resetExpandState,
          isTableLoading,
          allRecordsCount,
          currentApiEndpoint: url,
          updateSearchParams,
          apiParams,
        }}
      >
        {props.navbar ? (
          <TableNavbar
            {...{
              input: props.navbar.input,
              permissions_modal: props.navbar.permissions_modal,
            }}
          />
        ) : null}
        <TableFilters {...{ filters, setFilters }} />
        {checkboxes.length ? (
          <>
            <hr className="break-line" />
            <BulkActionsButtons {...{ bulkActions }} />
          </>
        ) : null}
        {isTableLoading ? (
          <LoadingTable {...{ headers, records }} />
        ) : (
          <BaseTable
            {...{
              tableHeaders: headers,
              records,
            }}
          />
        )}
      </TableContext.Provider>

      <Pagination
        currentPage={page}
        totalCount={allRecordsCount}
        pageSize={pageSize}
        onPageChange={(value: number) => {
          updateSearchParams("page", value.toString());
          //Reset BulkSelection
          checkboxes.length && checkboxesHandler([]);
        }}
        pageSizeHandler={(value: number) => {
          updateSearchParams("page_size", value.toString());
          //Reset BulkSelection
          checkboxes.length && checkboxesHandler([]);
        }}
        siblingCount={paginationSiblingCount}
      />
    </div>
  );
};
