import { FC } from "react";

import { GetAction } from "./components/GetAction";
import { DropdownMenu } from "@/components/DropdownMenu";
import { Action } from "@/components/Table/types/TableActions";
import { GenerateActionsProps } from "./types";

import "./scss/index.scss";

export const GenerateActions: FC<GenerateActionsProps> = ({
  click,
  actions,
  record,
}) => {
  const className: string = ` table-row__actions-container__actions ${
    click ? "active" : undefined
  } drop-shadow-medium `;

  const options = actions.map((action) => {
    const currentAction: Action =
      typeof action === "object" ? action.current : action;
    const config: object | undefined =
      typeof action === "object" ? action.config : undefined;

    return <GetAction {...{ action: currentAction, record, config }} />;
  });

  return (
    <div className={className}>
      <DropdownMenu {...{ options }} />
    </div>
  );
};
