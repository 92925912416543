import { CheckBox } from "@/components/CheckBox";
import { DropdownCheckboxItemProps } from "./types";
import { DropdownOptionTitle } from "../../types";

import "./scss/index.scss";

export const DropdownMenuCheckboxItem = <T,>({
  title,
  value,
  dropdownCheckboxes,
  dropdownCheckboxesHandler,
  dropdownCheckboxesSingleSelect,
}: DropdownCheckboxItemProps<T>) => (
  <div className={"drop-down-checkbox-container"}>
    <div className="drop-down-checkbox-container-content">
      <CheckBox
        checked={dropdownCheckboxes.includes(value as DropdownOptionTitle)}
        id={String(title)}
        onClick={() => dropdownCheckboxesHandler(value as DropdownOptionTitle)}
      />
      <label
        htmlFor={String(title)}
        className="drop-down-checkbox-button-label"
      >
        {title}
      </label>
    </div>
    <button
      className="drop-down-checkbox-container-only-button"
      onClick={() =>
        dropdownCheckboxesSingleSelect(value as DropdownOptionTitle)
      }
    >
      Only
    </button>
  </div>
);
