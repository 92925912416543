/**
 * Validates the URL passed to the hook.
 *
 * @param url - The URL to validate.
 * @throws An error if the URL is not defined.
 */
export const validateUrl = (url: string) => {
  const protocol: string = "^(?:https?://)?"; // http or https
  const port: string = "(?::\\d+)?"; // :XXXXX port number
  const any_path: string = "(?:/[^/\\s]+)*/?"; // /orders/details/  (with or without leading and trailing slash)
  const query_string: string =
    "?(?!.*&&)(?:\\?[;&a-z\\d%_.~+=-]*)?(?:\\#[-a-z\\d_]*)?"; // query string
  const full_url_path: string = port + any_path + query_string + "$"; // combination of every possible part of a url after the domain name

  const domain_name: string =
    protocol +
    "(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?[.])+[a-z]{2,}" +
    full_url_path; // validates regular domains
  const IPv4: string =
    protocol + "(?:(\\d{1,3}\\.){3}\\d{1,3})" + full_url_path; // validates IPv4 domains
  const localhost: string = protocol + "localhost" + full_url_path; // validates localhost on local machine
  const api_path: string = "^/?api" + any_path + query_string + "$"; // validates the api path

  const urlPattern: RegExp = new RegExp(
    domain_name + "|" + IPv4 + "|" + localhost + "|" + api_path,
    "i"
  ); // combination of all iterations (case insensitive)

  const isValid: boolean = urlPattern.test(url);

  if (isValid) return;

  console.error("Wrong URL format provided");

  throw new Error("Data wasn't found, please provide a correct URL");
};
