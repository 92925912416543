import { IoMdEye } from "react-icons/io";

import { Button } from "@/components/Button";
import { ButtonProps } from "./types";

import "./scss/index.scss";

export const Buttons = ({
  submitReview,
  onCloseModal,
  isAnyEmail,
  isAnySubject,
  isAnyMessage,
}: ButtonProps) => (
  <div className="buttons-container">
    <Button
      className="cancel-button"
      size={"small"}
      state={"link"}
      color="primary"
      label="Cancel"
      onClick={onCloseModal}
    />
    <Button
      disabled={isAnyEmail || isAnySubject || isAnyMessage}
      size={"small"}
      state={"primary"}
      className="review-icon"
      color="primary"
      label="Review"
      rightIcon={IoMdEye}
      onClick={submitReview}
    />
  </div>
);
