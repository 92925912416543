let URLs = require("../../" + process.env.REACT_APP_API_URL_FILENAME);

type URLPropertyType = {
  [key: string]: string;
};

export const getURL = (
  URLShortcut: string,
  URLProperties?: URLPropertyType
) => {
  if (!URLs) throw new Error("Variable URLs is not defined");

  let URLPattern: string = URLs[URLShortcut];

  if (URLProperties) {
    for (const URLProperty in URLProperties) {
      URLPattern = URLPattern.replace(
        "<" + URLProperty + ">",
        URLProperties[URLProperty]
      );
    }
  }

  return URLPattern;
};
