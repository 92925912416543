import { FC } from "react";

import { InfoTableProps } from "./types/infoTable";

import "./styles.scss";

export const InfoTable: FC<InfoTableProps> = ({
  primaryMessage,
  secondaryMessage,
  icon: Icon,
}) => (
  <table className="base-table">
    <tbody>
      <tr className="info-table__container">
        <td className="info-table__container__sub-container">
          {Icon ? (
            <Icon data-testid="info-table-icon" className="icon" />
          ) : null}

          <div className="info-table__primaryMessage">{primaryMessage} </div>

          <div className="info-table__secondaryMessage">{secondaryMessage}</div>
        </td>
      </tr>
    </tbody>
  </table>
);
