import { FC } from "react";

import { Icon } from "../Icon";
import { ButtonProps, ButtonComponent } from "./types";
import { IconColor } from "../Icon/types";

import "./scss/index.scss";

/**
 * {@link Button | Button}
 *
 * @param label - Text inside the component
 * @param size - Component size, options "x-small" | "small" | "medium" | "large"
 * @param color - Color type of the component, options "primary" | "success" | "destructive"
 * @param state- Style in which the component will be rendered, options "primary" | "secondary" | "outlined" | "link"
 * @param leftIcon - Icon to be rendered inside the component
 * @param rightIcon - Icon to be rendered inside the component
 * @param onClick - Function to run on click
 * @param testID - string to assign the id when running unit tests
 * @param className - ClassName to add custom styles
 * @param disabled - Boolean to toggle disabled state
 *
 * @returns JSX element, Button component
 *
 * @example
 * Simple usage:
 * ```
 * <Button
 *  onClick={testFunction}
 *  size={"small"}
 *  color={"primary"}
 *  state={"primary"}
 * />
 * ```
 *
 * @example
 * With icons:
 * ```
 * <Button
 *  onClick={testFunction}
 *  size={"small"}
 *  color={"primary"}
 *  state={"primary"}
 *  leftIcon={IoAccessibility}
 *  rightIcon={IoAccessibility}
 * />
 * ```
 *
 * @privateRemarks
 * {@link https://bloodbuy.atlassian.net/browse/BB-4398 - Jira Story}
 *
 * {@link https://www.figma.com/file/z2WZdtAPhE6soo9OLzSm6k/Bloodbuy-Buttons?t=8gpgvaOgOx6TTqfV-0 - Figma design}
 */
export const Button: FC<ButtonProps> = ({
  leftIcon,
  rightIcon,
  label,
  onClick,
  size,
  color,
  state,
  className,
  disabled,
  testID,
}): ButtonComponent => {
  const customClassName: string = `${
    label ? "button" : "button--only-icon"
  } ${color}--${state} ${size} ${className}`;

  const iconColor: IconColor = state === "primary" ? undefined : color;

  return (
    <button
      data-testid={testID}
      onClick={onClick}
      className={customClassName}
      disabled={disabled}
    >
      <Icon
        {...{
          icon: leftIcon,
          size,
          testID: "left-icon",
          state: "link",
          color: iconColor,
        }}
      />
      {label ? <span className="button-label">{label} </span> : null}
      <Icon
        {...{
          icon: rightIcon,
          size,
          testID: "right-icon",
          state: "link",
          color: iconColor,
        }}
      />
    </button>
  );
};
