import { useContext } from "react";

import { CheckBox } from "@/components/CheckBox";
import { TableContext } from "@/components/Table/context";
import { TableRowBulkActionProps } from "./types";

export const TableRowBulkAction = ({
  record: currentRecord,
}: TableRowBulkActionProps) => {
  const { toggleCheckbox, checkboxes, bulkActions } = useContext(TableContext);

  const isActive: boolean =
    checkboxes?.some(
      (record: Record<string, any>) => record.id === currentRecord.id
    ) || false;
  return bulkActions ? (
    <td>
      <CheckBox
        onClick={() => toggleCheckbox?.(currentRecord)}
        checked={isActive}
      />
    </td>
  ) : null;
};
