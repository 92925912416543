import { useEffect, useRef } from "react";

type Open = [open: boolean, setOpen: (val: boolean) => void];

export const useGetDropdownRef = ([open, setOpen]: Open) => {
  const dropdownRef = useRef<any>(null);

  // Function to handle the click outside event
  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    )
      setOpen(false);
  };

  // Add a click event listener when the dropdown is open
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [open]);

  return dropdownRef;
};
