import { CollapseRowOptions } from "@/components/Table/types/TableCollapseRow";

export const transformOptions = (
  options: CollapseRowOptions,
  record: { [val: string]: any }
): Record<string, string> => {
  const formattedOptions: Record<string, string> = {};

  for (const [key, value] of Object.entries(options)) {
    const [property, subProperty] = Array.isArray(value)
      ? value
      : [value, null];

    formattedOptions[key] = subProperty
      ? record[property]?.[subProperty]
      : record[property];
  }

  return formattedOptions;
};
