import { IoSearchCircle } from "react-icons/io5";
import { FC } from "react";

import { useTableRows } from "./hooks/useTableRows";
import { TableHeaders } from "./components/TableHeaders";
import { InfoTable } from "../InfoTable";
import { BaseTableProps } from "./types";
import { RowHeaders } from "./hooks/useTableRows/types";

import "./style.scss";

export const BaseTable: FC<BaseTableProps> = ({ records, tableHeaders }) => {
  const rowHeaders: RowHeaders = tableHeaders.map(({ title, ...props }) => ({
    ...props,
  }));

  const { render: TableRows, resetExpandState } = useTableRows({
    records,
    rowHeaders,
  });

  const hasRecords: boolean = Boolean(records?.length);

  if (!hasRecords) {
    return (
      <InfoTable
        primaryMessage="No results found"
        secondaryMessage="Try adjusting your search keyword and/or filters to find what you're looking for"
        icon={IoSearchCircle}
      />
    );
  }

  return (
    <table className="base-table">
      <TableHeaders {...{ tableHeaders, resetExpandState }} />
      <tbody className="base-table__body">{TableRows}</tbody>
    </table>
  );
};
