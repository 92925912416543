import { FC } from "react";

import { PaginationPageSizeOptions } from "../components/PaginationPageSizeOptions";
import { DetailsProps } from "./types";

/**
 * Displays the pagination current page, and total number of records
 * Plus, the option to change the number of records per page
 *
 * @param currentPage - The current page of the pagination component
 * @param pageSize - The number of records to display per page
 * @param totalCount - Total number of records
 * @param pageSizeOptions - Page size options to update the current page size
 * @param pageSizeHandler - Set the current page size
 */
export const PaginationDetails: FC<DetailsProps> = ({
  currentPage,
  pageSize,
  totalCount,
  pageSizeOptions,
  pageSizeHandler,
}) => {
  const paginationStartRange: number =
    currentPage > 1 ? pageSize * currentPage - (pageSize - 1) : currentPage;

  const paginationEndRange: number =
    pageSize * currentPage < totalCount ? pageSize * currentPage : totalCount;

  return (
    <div className="pagination-info">
      <span className="pagination-info__range">
        {paginationStartRange} - {paginationEndRange}{" "}
      </span>{" "}
      of {totalCount} entries
      <PaginationPageSizeOptions
        {...{ pageSizeOptions, pageSizeHandler, pageSize }}
      />
    </div>
  );
};
