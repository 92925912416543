import { Tooltip } from "react-tooltip";
import { IoMdClose } from "react-icons/io";
import { IoShareSocial } from "react-icons/io5";
import { useContext } from "react";

import { Icon } from "@/components/Icon";
import { Button } from "@/components/Button";
import { useDetectClickOutside } from "@/components/Table/hooks/useDetectClickOutside";
import { TableContext } from "@/components/Table/context";

export const TableNavbarShareButton = () => {
  const currentURL: string = window.location.href;
  const { clickedOutside, ref, clickedOutsideHandler, clickedOutsideToggle } =
    useDetectClickOutside();

  const { isTableLoading } = useContext(TableContext);

  return (
    <div ref={ref} className="share-container">
      <Icon
        icon={IoShareSocial}
        state={"link"}
        color="neutral"
        size="medium"
        onClick={clickedOutsideToggle}
        type="button"
        disabled={isTableLoading}
      />
      {clickedOutside ? (
        <div className="share-modal">
          <div className="header-row">
            <div className="header">Share URL</div>
            <Icon
              icon={IoMdClose}
              state={"link"}
              color="neutral"
              size="small"
              onClick={() => clickedOutsideHandler(false)}
            />
          </div>
          <div className="share-modal-content">
            <div className="url-container">{currentURL}</div>
            <div className="share-modal-note">
              <span className="note-highlight">NOTE:</span> Only people who can
              already log in to Bloodbuy can use this link.
            </div>
            <a
              data-tooltip-id="copy-tooltip"
              data-tooltip-content="Copied"
              data-tooltip-delay-hide={1000}
            >
              <Button
                label={"Copy Link"}
                size={"small"}
                state={"primary"}
                color="primary"
                onClick={() =>
                  navigator.clipboard.writeText(window.location.href)
                }
              />
            </a>
            <Tooltip openOnClick id="copy-tooltip" />
          </div>
        </div>
      ) : null}
    </div>
  );
};
