import { FC } from "react";

import { Icon } from "@/components/Icon";
import { LeftAlignmentProps } from "./types";

import "../scss/index.scss";

export const LeftAlignment: FC<LeftAlignmentProps> = ({
  icon,
  title,
  customIcon,
}) => (
  <>
    {customIcon || icon ? (
      <Icon
        colorClassName={"drop-down-button-icon"}
        state={"link"}
        icon={customIcon || icon}
        color="neutral"
      />
    ) : null}
    <span className="drop-down-button-label">{title}</span>
  </>
);
