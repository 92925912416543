import { z } from "zod";

import { HandleKeyPressProps } from "./types";

export const handleKeyPress = ({
  event,
  inputRef,
  emailsHandler,
  errorHandler,
}: HandleKeyPressProps) => {
  if (event.key !== "Enter") return;
  const resetInput = () => inputRef.current && (inputRef.current.value = "");
  try {
    const email: string = event.currentTarget.value;
    z.coerce.string().email().max(320).min(3).parse(email);
    emailsHandler(email);
    resetInput();
  } catch (error: any) {
    const zodError: string = error.issues[0].message;
    errorHandler(zodError);
    resetInput();
  }
};
