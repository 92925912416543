import { FC } from "react";

import { Badge } from "@/components/Badge";
import { BadgeColor, BadgeSize, BadgeState } from "@/components/Badge/types";
import { RenderBadgeType } from "./types";
import { NoValue } from "../NoValue";

export const RenderBadge: FC<RenderBadgeType> = ({
  value,
  properties,
}): JSX.Element | null => {
  if (!value) return <NoValue />;

  const formattedValue: string = value?.toLowerCase().split(" ").join("-");
  const { badgeColor, badgeState, badgeSize } = properties ?? {};
  const color = badgeColor?.[formattedValue] as BadgeColor;
  const size = badgeSize?.[formattedValue] as BadgeSize;
  const state = badgeState?.[formattedValue] as BadgeState;

  return (
    <Badge
      label={value}
      size={size || "small"}
      color={color || "neutral"}
      state={state || "secondary"}
    />
  );
};
