import { BiDownload } from "react-icons/bi";
import { useContext } from "react";

import { DropdownMenu } from "@/components/DropdownMenu";
import { Icon } from "@/components/Icon";
import { useDetectClickOutside } from "@/components/Table/hooks/useDetectClickOutside";
import { downloadTableData } from "../../utils/downloadTableData";
import { TableContext } from "@/components/Table/context";
import { TableExportOptions } from "@/components/Table/types/TableFilters";

import "./scss/index.scss";

export const DownloadButton = ({ param_name, choices }: TableExportOptions) => {
  const { currentApiEndpoint, apiParams } = useContext(TableContext);

  const { clickedOutside, ref, clickedOutsideToggle } = useDetectClickOutside();

  return (
    <div ref={ref} className="table-navbar-download-button">
      <Icon
        icon={BiDownload}
        onClick={clickedOutsideToggle}
        state="link"
        color="neutral"
        size="medium"
      />

      {clickedOutside ? (
        <div style={{ position: "relative" }}>
          <DropdownMenu<string>
            title={"Download as..."}
            onClick={({ value: exportFormat }) => {
              clickedOutsideToggle();
              return downloadTableData({
                exportParamName: param_name,
                exportFormat,
                currentApiEndpoint,
                apiParams,
              });
            }}
            options={choices}
          />
        </div>
      ) : null}
    </div>
  );
};
