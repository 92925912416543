import { FC, useContext } from "react";

import { useModal } from "@/components/Table/hooks/useModal";
import { TableContext } from "@/components/Table/context";
import { EditUserPermissionsModal } from "./template/index";

type EditUserPermissionsType = {
  record: Record<string, string>;
};

export const EditUserPermissions: FC<EditUserPermissionsType> = ({
  record,
}) => {
  const { updateRecord } = useContext(TableContext);
  const { modal, onOpenModal } = useModal(EditUserPermissionsModal, {
    record,
    updateRecord,
  });

  return (
    <>
      <button className="drop-down-button" onClick={onOpenModal}>
        Edit User Permissions
      </button>
      {modal}
    </>
  );
};
