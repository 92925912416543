import { DateRangeWithoutStatus } from "@/components/Table/hooks/useTableLogic/types";

export const getLastMonth = (
  handleUpdateDateRange?: ({
    startDate,
    endDate,
  }: DateRangeWithoutStatus) => void
) => {
  const now = new Date();
  const firstDayPrevMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1);
  const lastDayPrevMonth = new Date(now.getFullYear(), now.getMonth(), 0);
  handleUpdateDateRange?.({
    startDate: firstDayPrevMonth,
    endDate: lastDayPrevMonth,
  });
};
