import Cookies from "universal-cookie";
import parse from "html-react-parser";

import { getURL } from "@/utils/getURL";
import { GetReviewProps } from "./types";
import { getBody } from "../getBody";

const cookies = new Cookies();

export const getReview = ({ data, reviewHandler }: GetReviewProps) => {
  const url = getURL("api:custom-email-message-review");

  (async () => {
    try {
      const fetchResponse: Response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": cookies.get("csrftoken"),
          "X-Requested-With": "XMLHttpRequest",
        },
        body: JSON.stringify({
          ...data,
          ...getBody({ data }),
        }),
      });

      const response: string = await fetchResponse.text();
      reviewHandler(parse(response));
    } catch (error) {
      console.error(error);
    }
  })();
};
