import { useState } from "react";

import { ReviewType } from "../../types";

export const useReview = () => {
  const [review, setReview] = useState<ReviewType>(null);
  const reviewHandler = (value: ReviewType) => {
    setReview(value);
  };

  return { review, reviewHandler };
};
