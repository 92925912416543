import { MutableRefObject, useRef } from "react";

import { useEmails } from "./hooks/useEmails";
import { useError } from "./hooks/useError";
import { EmailInputProps } from "./types";
import { useFacility } from "./hooks/useFacility";
import { useSuggestions } from "./hooks/useSuggestions";
import { handleKeyDown } from "./utils/handleKeyDown";
import { EmailsContainer } from "./components/EmailsContainer";
import { Suggestions } from "./components/Suggestions";

import "./scss/index.scss";

export const EmailInput = ({
  label,
  emails,
  dataHandler,
  addFacility,
  facilities,
  type,
  displayErrorHandler,
}: EmailInputProps) => {
  const inputRef: MutableRefObject<HTMLInputElement | null> =
    useRef<HTMLInputElement | null>(null);

  const { suggestions, suggestionsHandler } = useSuggestions(
    emails,
    facilities
  );
  const { error, errorHandler, resetErrorMessage } = useError();

  const { emailsHandler, removeEmail } = useEmails({
    emails,
    dataHandler,
    errorHandler,
  });

  const { facilityHandler, removeFacility } = useFacility({
    addFacility,
    emailsHandler,
    facilities,
    removeEmail,
    emails,
    errorHandler,
  });

  const onKeyDown = handleKeyDown({
    inputRef,
    emailsHandler,
    errorHandler,
    error,
    resetErrorMessage,
  });

  return (
    <div className={"email-input-container"}>
      <label>{label}</label>
      <EmailsContainer
        {...{
          suggestionsHandler,
          removeEmail,
          removeFacility,
          error,
          emails,
          inputRef,
          type,
          onKeyDown,
        }}
      />
      <Suggestions
        {...{
          type,
          inputRef,
          suggestions,
          suggestionsHandler,
          emailsHandler,
          facilityHandler,
          displayErrorHandler,
        }}
      />
      {error ? <span className="span-error">{error}</span> : null}
    </div>
  );
};
