import { AlertActions } from "../../../AlertActions";
import { DetailsContainerProps } from "./types/index";

import "./scss/index.scss";

export const DetailsContainer = ({
  header,
  description,
  actions,
  color,
}: DetailsContainerProps) => (
  <div className="alert-details-container">
    {header ? <div className={`header--${color}`}>{header}</div> : null}
    <div className={`description--${color}`}>{description}</div>
    <AlertActions {...{ color, actions }} />
  </div>
);
