import { GetDatePickerMonthsProps } from "./types";

//Return the last 3 months and one forward from the current month
// If we are in July it will return [April, May, June, July, August]
export const getDatePickerMonths = (
  currentDate: Date
): GetDatePickerMonthsProps => {
  const currentMonth = currentDate.getMonth(); // January is 0, December is 11
  const currentYear = currentDate.getFullYear();

  const monthsAndYears: { title: string; value: Date }[] = [];

  // Array of month names for formatting
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // Calculate last three months
  for (let i = 3; i > 0; i--) {
    let month = currentMonth - i;
    let year = currentYear;

    if (month < 0) {
      month += 12;
      year -= 1;
    }

    const date = new Date(year, month, 1);
    const title = `${monthNames[month]} - ${year}`;
    monthsAndYears.push({ title, value: date });
  }

  // Current month
  const currentDateObj = new Date(currentYear, currentMonth, 1);
  const currentTitle = `${monthNames[currentMonth]} - ${currentYear}`;
  monthsAndYears.push({ title: currentTitle, value: currentDateObj });

  // One month forward
  let nextMonth = currentMonth + 1;
  let nextYear = currentYear;

  if (nextMonth > 11) {
    nextMonth -= 12;
    nextYear += 1;
  }

  const nextDate = new Date(nextYear, nextMonth, 1);
  const nextTitle = `${monthNames[nextMonth]} - ${nextYear}`;
  monthsAndYears.push({ title: nextTitle, value: nextDate });

  return monthsAndYears;
};
