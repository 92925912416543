import { FC } from "react";

import { Icon } from "@/components/Icon";
import { RightAlignmentProps } from "./types";

import "../scss/index.scss";

export const RightAlignment: FC<RightAlignmentProps> = ({
  icon,
  title,
  customIcon,
}) => (
  <>
    <span className="drop-down-button-label">{title}</span>
    {customIcon || icon ? (
      <Icon
        colorClassName={"drop-down-button-icon"}
        state={"link"}
        icon={customIcon || icon}
        color="neutral"
      />
    ) : null}
  </>
);
