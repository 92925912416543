import upArrow from "@/assets/images/svg/ic-chevron-up.svg";
import downArrow from "@/assets/images/svg/ic-chevron-down.svg";
import { TableHeaderItemIconProps } from "./types";

import "./scss/index.scss";

export const TableHeaderItemIcon = ({
  sortBy: currentSortBy,
  headerApiParam,
}: TableHeaderItemIconProps) => {
  const sortBy: string | undefined = currentSortBy?.replace("-", "");
  const isActive: boolean = sortBy === headerApiParam;
  const isAscending: boolean = currentSortBy?.[0] !== "-";

  const iconSrc = isActive && !isAscending ? downArrow : upArrow;
  const className: string = isActive
    ? "table-header__image--show"
    : "table-header__image--hide";

  return (
    <img
      className={className}
      src={iconSrc}
      alt={isAscending ? "up-arrow" : "down-arrow"}
    />
  );
};
