import { useState } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

import { getDatePickerMonths } from "./utils/getDatePickerMonths";
import { DropdownMenu } from "@/components/DropdownMenu";
import { Icon } from "@/components/Icon";
import { CustomHeaderProps } from "./types";

export const CustomDatePickerHeader = ({
  monthDate,
  customHeaderCount,
  decreaseMonth,
  increaseMonth,
  changeMonth,
  changeYear,
}: CustomHeaderProps) => {
  const [openDropdown, setOpenDropdown] = useState<boolean>(false);

  return (
    <>
      <div
        aria-label="Previous Month"
        className={
          "react-datepicker__navigation react-datepicker__navigation--previous"
        }
        style={customHeaderCount === 1 ? { visibility: "hidden" } : undefined}
      >
        <Icon
          icon={IoIosArrowBack}
          color="neutral"
          size="small"
          state={"link"}
          onClick={decreaseMonth}
        />
      </div>
      <span className="react-datepicker__current-month">
        {monthDate.toLocaleString("en-US", {
          month: "short",
          year: "numeric",
        })}
        {/* Todo?: https://bloodbuy.atlassian.net/jira/software/c/projects/BB/boards/27?selectedIssue=BB-4819 */}
        {/* <Icon
          color="neutral"
          size={"small"}
          icon={TiArrowSortedDown}
          state={"link"}
          className="current-month--icon"
          onClick={() => openDropdownHandler(!openDropdown)}
        /> */}
      </span>
      <div
        aria-label="Next Month"
        className={
          "react-datepicker__navigation react-datepicker__navigation--next"
        }
        style={customHeaderCount === 0 ? { visibility: "hidden" } : undefined}
      >
        <Icon
          icon={IoIosArrowForward}
          color="neutral"
          state={"link"}
          size="small"
          onClick={increaseMonth}
        />
      </div>
      {openDropdown ? (
        <DropdownMenu
          className={`date-picker-drop-down-menu ${
            customHeaderCount === 1 ? "right-side" : ""
          }`}
          options={getDatePickerMonths(monthDate)}
          onClick={({ value }) => {
            if (!value) return;
            changeMonth(value.getMonth());

            if (
              monthDate.getFullYear() > value.getFullYear() &&
              monthDate.getFullYear() !== value.getFullYear()
            )
              changeYear(value.getFullYear());

            setOpenDropdown(false);
          }}
        />
      ) : null}
    </>
  );
};
