import { useEffect, useState } from "react";

import { UserType } from "./types";
import { getURL } from "@/utils/getURL";

export const useCurrentUser = (): UserType => {
  const [user, setUser] = useState<UserType>({
    user_id: 0,
    full_name: "",
    is_primary_contact: false,
    email: "",
    role: "",
    facility: {
      id: "",
      name: "",
    },
    is_active: false,
    permission_groups: [],
  });

  const url: string = getURL("api:internal-users-list") + "current/";

  useEffect(() => {
    try {
      const fetchUser = async () => {
        const response = await fetch(url);
        const user = await response.json();
        setUser(user);
      };

      fetchUser();
    } catch (error) {
      console.error(error);
    }
  }, []);

  return user;
};
