import { useState } from "react";

import { DropdownOptionProps } from "@/components/DropdownMenu/types";

export const useFilteredOptions = <T>(options: DropdownOptionProps<T>[]) => {
  const [filteredOptions, setFilteredOptions] =
    useState<DropdownOptionProps<T>[]>(options);

  const filteredOptionsHandler = (value: DropdownOptionProps<T>[]) =>
    setFilteredOptions(value);

  const resetFilteredOptions = () => setFilteredOptions(options);

  const isAnyFilteredOption = filteredOptions.length;

  return {
    filteredOptions,
    isAnyFilteredOption,
    filteredOptionsHandler,
    resetFilteredOptions,
  };
};
