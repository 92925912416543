import { FC } from "react";

import { getURL } from "@/utils/getURL";

import { Table } from "@/components/Table";
import { BlockedFacilitiesType } from "./types";
import { UserDetails } from "@/components/Table/templates/UserDetails";

export const BlockedFacilities: FC = () => {
  const url: string = getURL("api:blocked-facilities-list", {
    parent_lookup_pk: "current",
  });

  return (
    <Table<BlockedFacilitiesType>
      id={"facility_id"}
      navbar={{
        header: "Blocked Facilities",
        input: true,
        tooltip: {
          type: "i",
          message:
            "Once you block a facility, you can no longer transact with each other or view each other's Open Order or Package postings",
        },
      }}
      headers={[
        { title: "FACILITY", apiParam: "name" },
        { title: "TYPE", apiParam: "facility_type" },
        { title: "LOCATION", apiParam: "location" },
      ]}
      dropDown={{
        template: UserDetails,
        props: {
          collapseRow: {
            id: "user_id",
            primaryMessage: "${primary_contact.full_name}",
            secondaryMessage: "${primary_contact.role} at ${name}.",
            options: {
              Email: ["primary_contact", "email"],
              Phone: ["primary_contact", "formatted_office_number"],
              Mobile: ["primary_contact", "formatted_cell_number"],
            },
          },
        },
      }}
      url={url}
      actions={["view_profile", "submit_recall", "submit_disposition_form"]}
    />
  );
};
