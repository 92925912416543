import { DateRangeWithoutStatus } from "@/components/Table/hooks/useTableLogic/types";

export const getLast90Days = (
  handleUpdateDateRange?: ({
    startDate,
    endDate,
  }: DateRangeWithoutStatus) => void
) => {
  const today = new Date();
  const startDate = new Date();
  startDate.setDate(today.getDate() - 89);
  handleUpdateDateRange?.({ startDate, endDate: today });
};
