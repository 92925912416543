import { FC } from "react";

import { getURL } from "@/utils/getURL";
import { Table } from "@/components/Table";
import { UserDetails } from "@/components/Table/templates/UserDetails";
import { FacilityUserType } from "./types/facilityUser";
import { AffiliateFacilityUserType } from "./types/affiliateFacilityUser";
import { TableHeaders } from "@/components/Table/types/TableHeaders";

export const FacilityUsers: FC = () => {
  const headers: TableHeaders = [
    { title: "NAME", apiParam: "full_name" },
    { title: "EMAIL", apiParam: "email" },
    { title: "FACILITY", apiParam: ["facility", "name"] },
    {
      title: "ROLE",
      apiParam: "role",
      type: "badge",
      properties: {
        badgeColor: {
          "group-admin": "success",
          "tech": "neutral",
          "admin": "neutral",
        },
      },
    },
  ];
  const facilityURL: string = getURL("api:facility-users-list", {
    parent_lookup_pk: "current",
  });
  const affiliateURL: string = getURL("api:affiliate-users-list", {
    parent_lookup_pk: "current",
  });

  return (
    <>
      <Table<FacilityUserType>
        id={"user_id"}
        navbar={{
          header: "Users",
          tooltip: {
            type: "?",
            message: "A list of all users in your facility.",
          },
          input: true,
        }}
        dropDown={{
          template: UserDetails,
          props: {
            collapseRow: {
              id: "user_id",
              primaryMessage: "${full_name}",
              secondaryMessage: "${role} at ${facility.name}.",
              options: {
                Email: "email",
                Phone: "formatted_office_number",
                Mobile: "formatted_cell_number",
              },
            },
          },
        }}
        headers={headers}
        url={facilityURL}
      />
      <hr style={{ margin: 0 }} />
      <Table<AffiliateFacilityUserType>
        id={"user_id"}
        navbar={{
          header: "Affiliate Users",
          tooltip: {
            type: "?",
            message:
              "A list of users outside of your facility that you have access to.",
          },
          input: true,
        }}
        headers={headers}
        dropDown={{
          template: UserDetails,
          props: {
            collapseRow: {
              id: "user_id",
              primaryMessage: "${full_name}",
              secondaryMessage: "${role} at ${facility.name}.",
              options: {
                Email: "email",
                Phone: "formatted_office_number",
                Mobile: "formatted_cell_number",
              },
            },
          },
        }}
        url={affiliateURL}
      />
    </>
  );
};
