import Cookies from "universal-cookie";

import { getURL } from "@/utils/getURL";

const cookies = new Cookies();

type SubmitPermissions <T extends Record<string, any>> =  {
  user_id: string;
  permissions: T[];
  updateRecord: (value: T[], prop: string, user_id: string) => void;
  onCloseModal: () => void;
};

export const submitPermissions = <T extends Record<string, any>>({
  user_id,
  permissions,
  updateRecord,
  onCloseModal,
}: SubmitPermissions<T>) => {
  return async () => {
    type Serializer = {
      user_id: number;
      groups: { id: number; name: string }[];
    };

    const serializer: Serializer = {
      user_id: +user_id,
      groups: permissions.map(({ id, name }) => {
        return { id, name };
      }),
    };

    const url: string = getURL("api:internal-users-detail", {
      pk: user_id,
    });

    const headers = {
      "X-CSRFToken": cookies.get("csrftoken"),
      "Content-Type": "application/json",
    };

    try {
      const response = await fetch(url, {
        method: "PATCH",
        headers,
        body: JSON.stringify(serializer),
      });
      const data = await response.json();

      if (data.result === "success")
        updateRecord(permissions, "permission_groups", user_id);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      onCloseModal();
    }
  };
};
