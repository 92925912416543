import { DateRangeWithoutStatus } from "@/components/Table/hooks/useTableLogic/types";

export const getLast3Months = (
  setDateRangeHandler?: ({ startDate, endDate }: DateRangeWithoutStatus) => void
) => {
  const now: Date = new Date();
  const startOfCurrentMonth: Date = new Date(
    now.getFullYear(),
    now.getMonth(),
    1
  );
  const endOfLastMonth: Date = new Date(startOfCurrentMonth.getTime() - 1);
  const startOf3MonthsAgo: Date = new Date(
    endOfLastMonth.getFullYear(),
    endOfLastMonth.getMonth() - 2,
    1
  );

  setDateRangeHandler?.({
    startDate: startOf3MonthsAgo,
    endDate: endOfLastMonth,
  });
};
