import { FC } from "react";

type PermissionsBulletType = {
  permissions: { name: string; id: number }[];
};

export const PermissionsBullet: FC<PermissionsBulletType> = ({
  permissions,
}) => (
  <>
    {permissions.map(({ name, id }) => (
      <li key={id} className="bullet">
        {name}
      </li>
    ))}
  </>
);
