import { FC } from "react";

import { UserDetailsHeaderProps } from "./types";

import "./scss/index.scss";

export const UserDetailsHeader: FC<UserDetailsHeaderProps> = ({
  primaryMessage,
  secondaryMessage,
}) =>
  primaryMessage ? (
    <h2 className="collapse-container__header">
      <span className="collapse-container__header__primary">
        {primaryMessage}
      </span>
      {secondaryMessage ? (
        <span className="collapse-container__header__secondary">
          {", "}
          {secondaryMessage}
        </span>
      ) : null}
    </h2>
  ) : (
    <h2 className="collapse-container__header">
      <span className="collapse-container__header__primary">
        Primary Contact not listed
      </span>
    </h2>
  );
