import { FC } from "react";

import { NoValue } from "../NoValue";
import { RenderArrayType } from "./types";
import { Default } from "./component/Default";

export const RenderArray: FC<RenderArrayType> = ({ value, properties }) => {
  const arrayIsEmpty: boolean = !value.length;
  const { className, key } = properties ?? {};

  if (arrayIsEmpty) return <NoValue />;
  if (!key) return <Default {...{ className, value }} />;

  const serializer = value.map(
    (record: Record<string, unknown>) => record[key]
  );
  return <span className={className}>{serializer.sort().join(", ")}</span>;
};
