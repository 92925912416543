import { Bounce, ToastOptions } from "react-toastify";

export const TOAST_PROPS: ToastOptions = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "dark",
  transition: Bounce,
};

export const DOWNLOAD_ERROR_STATUS: number[] = [202, 400, 413, 429];

export const STATUS_500_ERROR_MESSAGE: string =
  "Internal server error, please try again later";

export const USER_FRIENDLY_GENERIC_ERROR_MESSAGE: string =
  "Something went wrong. Please try again later";

export const STATUS_204_WARNING_MESSAGE: string = "No data to download";
