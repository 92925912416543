import { KeyboardEvent } from "react";

import { handleKeyPress } from "./handleKeyPress";

type HandleKeyDown = any;

export const handleKeyDown =
  ({
    inputRef,
    emailsHandler,
    errorHandler,
    error,
    resetErrorMessage,
  }: HandleKeyDown) =>
  (event: KeyboardEvent<HTMLInputElement>) => {
    handleKeyPress({ event, inputRef, emailsHandler, errorHandler });
    error && resetErrorMessage();
  };
