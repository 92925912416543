import { ChangeEvent } from "react";
import debounce from "lodash.debounce";

import { TextBoxProps } from "./types";

import "./scss/index.scss";

export const TextBox = ({
  message,
  label,
  dataHandler,
  value,
}: TextBoxProps) => {
  const onChange = (event: ChangeEvent<HTMLTextAreaElement>) =>
    dataHandler(event.target.value.trim());
  const debounceOnChange = debounce(onChange, 500);
  const numberOfCharacters: number = message.length;
  const maxLength: number = 1000;

  return (
    <div className="text-area-container">
      <label>{label}</label>
      <textarea
        onChange={debounceOnChange}
        name="compose"
        id="compose"
        cols={20}
        defaultValue={value}
        rows={7}
        maxLength={maxLength}
      ></textarea>
      <div className="number-of-characters">
        {numberOfCharacters} / {maxLength} characters remaining
      </div>
    </div>
  );
};
