import { useRef, useState, useEffect } from "react";

/**
 * Custom hook that tracks clicks outside an element.
 *
 * @param ref - A ref to the element that should be monitored for clicks.
 * @returns An object containing the current click state and functions to update the click state and attach the ref to an element.
 */
export const useDetectClickOutside = (onClickOutsideAction?: () => void) => {
  const ref = useRef<HTMLDivElement | any>(null);
  const [clickedOutside, setClickedOutside] = useState<boolean>(false);

  const clickedOutsideToggle = () => setClickedOutside(!clickedOutside);
  const clickedOutsideHandler = (value: boolean) => setClickedOutside(value);

  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setClickedOutside(false);
      onClickOutsideAction?.();
    }
  };

  useEffect(() => {
    clickedOutside
      ? document.addEventListener("mousedown", handleClickOutside)
      : document.removeEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [clickedOutside]);

  return {
    clickedOutside,
    ref,
    clickedOutsideHandler,
    clickedOutsideToggle,
  };
};
