import { FC } from "react";

import { getURL } from "@/utils/getURL";
import { Table } from "@/components/Table";
import { useFilters } from "@/hooks/useFilters";
import { BlockIcon } from "../assets";

const url: string = getURL("api:internal-open-orders-list");
const filtersURL: string = getURL("api:internal-open-orders-schema-options");

export const OpenOrders: FC = () => {
  const filterOptions = useFilters(filtersURL);

  return (
    <Table
      id={"id"}
      sortBy="-last_modified"
      navbar={{ header: "All Open Orders", input: true }}
      headers={[
        { title: "#", apiParam: "id" },
        { title: "LAST MODIFIED", apiParam: "last_modified", type: "date" },
        { title: "BUYER", apiParam: "buyer" },
        { title: "ITEMS", apiParam: "component_string" },
        { title: "LISTED FOR", apiParam: "expire_after" },
        { title: "SHARED WITH", apiParam: "shared_with" },
        { title: "AUDIENCE", apiParam: "audience" },
        {
          title: "STATUS",
          apiParam: "state",
          type: "badge",
          properties: {
            badgeColor: {
              "expired": "destructive",
              "open": "primary",
              "fulfilled": "success",
            },
          },
        },
        {
          title: "BLOCKED",
          apiParam: "has_block",
          type: "boolean",

          properties: {
            booleanType: {
              true: <BlockIcon />,
            },
          },
        },
      ]}
      filters={filterOptions}
      url={url}
    />
  );
};
