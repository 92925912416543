import { useEffect, useState } from "react";

import { useSearchParams } from "@/hooks/useSearchParams";
import { useParam } from "./hook/useParam";
import {
  FiltersType,
  TableFiltersOptions,
} from "@/components/Table/types/TableFilters";

export const useFilters = (options?: TableFiltersOptions) => {
  const activeFilters = useParam(options ?? {});
  const { searchParams, updateSearchParams } = useSearchParams();
  const [filters, setFilters] = useState<FiltersType[]>(activeFilters);

  useEffect(() => {
    setFilters(activeFilters);
  }, [options]);

  const updateFilters = (value: FiltersType) => {
    const {
      header: { title: headerTitle, value: filterName },
      subHeader: { value: filterValue },
    } = value;

    const isFilterActive: boolean = searchParams.has(filterName, filterValue);
    if (isFilterActive) return;

    updateSearchParams(filterName, filterValue);

    setFilters((prev) => [
      ...prev.filter((obj) => obj.header.title !== headerTitle),
      value,
    ]);
    updateSearchParams("page", "1");
  };

  return { filters, setFilters, updateFilters };
};
