/**
 * Replaces placeholders in the input text with corresponding values from the record object.
 * Placeholders should be in the form ${key}, and the corresponding value from the record
 * object will be substituted in place of the placeholder.
 *
 * @returns The input text with placeholders replaced by their corresponding values.
 *
 * @example
 * const object = { name: 'Mario', location: 'Florida', test: { one: 'hello', two: 'bye' } };
 * const replacedText = replacePlaceholders('{name} at {location}', object);
 * // Output: 'Mario at Florida'
 *
 * const nestedReplacedText = replacePlaceholders('{name} says "{test.one}"', object);
 * // Output: 'Mario says "hello"'
 */

export const replacePlaceholders = (text: string, record: object): string =>
  text.replace(/\${([\w.]+)}/g, (_, key) => {
    const keys = key.split(".");
    let value: any = record;

    for (const key of keys) {
      if (!value) return "";
      value = value[key];
    }

    return value;
  });
