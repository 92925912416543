import { HandleKeyDown } from "./types";
import { validateDate } from "./utils/validateDate";
import { DEFAULT_DATE_PICKER_DATE_FORMAT } from "../../../DatePicker/constants";

export const handleKeyUp = ({
  event,
  handleUpdateSelectedDateRange,
  changeMonthRef,
  changeYearRef,
  handleUpdateDateRange,
  handleInvalidState,
  isInvalid,
}: HandleKeyDown) => {
  handleUpdateSelectedDateRange("Custom Range");
  const dateRange: string = (event.target as HTMLInputElement).value;
  const isValidDate = validateDate(handleInvalidState, isInvalid);

  if (dateRange === DEFAULT_DATE_PICKER_DATE_FORMAT) return;

  const [startDateString, endDateString] = dateRange
    .split("—")
    .map((date) => date.trim());

  if (!isValidDate(startDateString) || !isValidDate(endDateString)) return;

  const startDate: Date = new Date(startDateString);
  const endDate: Date = new Date(endDateString);

  changeMonthRef.current?.(startDate.getMonth());
  changeYearRef.current?.(startDate.getFullYear());

  handleUpdateDateRange?.({
    startDate,
    endDate,
  });
};
