import { useContext } from "react";

import { LoadingRow } from "./components/LoadingRow";
import { TableContext } from "@/components/Table/context";
import { TableHeaders } from "@/components/Table/components/BaseTable/components/TableHeaders";

export const LoadingTable = ({ headers, records }: any) => {
  const numberOfRows = records.length ? records : [null];

  const { bulkActions } = useContext(TableContext);

  return (
    <table className="base-table">
      <TableHeaders {...{ tableHeaders: headers }} />
      <tbody className="base-table__body">
        {numberOfRows.map(() => (
          <tr className="table-row">
            {bulkActions ? (
              <td style={{ width: "4%" }}></td>
            ) : (
              <td style={{ width: "0%" }}> </td>
            )}
            {headers.map(({ title }: any) => (
              <td key={title} className="table-row__cell">
                <LoadingRow />
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
