import { FC } from "react";

import { Button } from "@/components/Button";
import { Permission } from "./components/Permission";
import { getURL } from "@/utils/getURL";
import { useFetch } from "@/components/Table/hooks/useFetch";
import { PermissionType } from "./types";

type PermissionsModalType = {
  onCloseModal: () => void;
};

export const PermissionsModal: FC<PermissionsModalType> = ({
  onCloseModal,
}) => {
  const url: string = getURL("api:groups-list");

  const { results } = useFetch<PermissionType>({ url });
  return (
    <div className="modal-container">
      <h2 className="header">User Permissions</h2>
      <br />
      {results?.map(({ name, permissions, id, short_name }: PermissionType) => (
        <Permission key={id} {...{ name, permissions, short_name }} />
      ))}
      <br />
      <Button
        className={"right-alignment"}
        onClick={onCloseModal}
        size={"medium"}
        color={"primary"}
        state={"primary"}
        label={"Close"}
      />
    </div>
  );
};
