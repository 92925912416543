import debounce from "lodash.debounce";

import { InputProps } from "./types/index";

import "./scss/index.scss";

export const Input = ({ label, inactive, value, dataHandler }: InputProps) => {
  const inputClassName: string = `emailer-input ${inactive ? "inactive" : ""}`;
  const divClassName: string = `emailer-input-container ${
    inactive ? "inactive" : ""
  }`;
  const labelClassName: string = `input-label ${inactive ? "inactive" : ""}`;

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    dataHandler && dataHandler(event.target.value.trim());

  const debounceOnChange = debounce(onChange, 1000);

  return (
    <div className={divClassName}>
      <label className={labelClassName}>{label}</label>
      <input
        key={value}
        readOnly={inactive}
        defaultValue={value}
        type="text"
        id={label}
        className={inputClassName}
        onChange={debounceOnChange}
      />
    </div>
  );
};
