import debounce from "lodash.debounce";

import { Badge } from "@/components/Badge";
import { BadgeColor, BadgeState } from "@/components/Badge/types";
import { onChange } from "./utils/onChange";
import { CrossIcon } from "./assets/CrossIcon";
import { EmailContainerProps } from "./types";

export const EmailsContainer = ({
  suggestionsHandler,
  removeEmail,
  removeFacility,
  onKeyDown,
  error,
  emails,
  inputRef,
  type,
}: EmailContainerProps) => {
  const badgeColor: Record<string, BadgeColor> = {
    facility: "success",
    user: "primary",
  };

  const badgeState: Record<string, BadgeState> = {
    facility: "primary",
    user: "secondary",
  };

  type OnClick = { index: number; badge: string };
  const onClick = (type: string, { index, badge }: OnClick) => {
    return {
      user: removeEmail(badge),
      facility: removeFacility(index),
    }[type];
  };

  //Time in milliseconds
  const timeToDebounce: number = 680; //Sweet spot for fetching suggestions speed
  const debounceOnChange = debounce(
    onChange(suggestionsHandler, type),
    timeToDebounce
  );
  return (
    <div
      className={`emails-container ${error ? "error" : ""}`}
      onClick={() => inputRef.current?.focus()}
    >
      {emails.map((email: string, index: number) => {
        const label: string =
          type === "facility" ? `Email all users at ${email}` : email;

        return (
          <Badge
            key={email}
            label={label}
            size={"small"}
            color={badgeColor[type]}
            state={badgeState[type]}
            onClick={(badge: string) => onClick(type, { index, badge })}
            rightIcon={CrossIcon}
          />
        );
      })}

      <input
        ref={inputRef}
        onChange={debounceOnChange}
        onKeyDown={onKeyDown}
        type="text"
        className="input"
      />
    </div>
  );
};
