import { toast } from "react-toastify";

import { DownloadTableDataProps } from "./types";
import {
  DOWNLOAD_ERROR_STATUS,
  STATUS_500_ERROR_MESSAGE,
  TOAST_PROPS,
  USER_FRIENDLY_GENERIC_ERROR_MESSAGE,
  STATUS_204_WARNING_MESSAGE,
} from "./constants";

export const downloadTableData = async ({
  exportParamName,
  exportFormat,
  currentApiEndpoint,
  apiParams,
}: DownloadTableDataProps) => {

  const url: string = `${currentApiEndpoint}?${apiParams}&${exportParamName}=${exportFormat}`;

  try {
    const response = await fetch(url);

    if (response.status === 204)
      return toast.warning(STATUS_204_WARNING_MESSAGE, TOAST_PROPS);

    if (DOWNLOAD_ERROR_STATUS.includes(response.status)) {
      const { detail } = await response.json();
      return toast.error(detail, TOAST_PROPS);
    }

    if (response.status > 200 && response.status < 500)
      return toast.warning(USER_FRIENDLY_GENERIC_ERROR_MESSAGE, TOAST_PROPS);

    if (response.status >= 500) throw new Error(STATUS_500_ERROR_MESSAGE);

    // Get the filename from the Content-Disposition header
    const disposition = response.headers.get("Content-Disposition");
    let fileName = "downloaded-file"; // Fallback filename

    if (disposition && disposition.includes("filename")) {
      const fileNameMatch = disposition.match(
        /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
      );
      if (fileNameMatch?.[1]) {
        fileName = fileNameMatch[1].replace(/['"]/g, "");
      }
    }

    // Create a Blob from the response data
    const blob = await response.blob();

    // Create a link element, use it to download the blob
    const link = document.createElement("a");
    const blobUrl = window.URL.createObjectURL(blob);
    link.href = blobUrl;
    link.download = fileName;

    // Append the link to the body
    document.body.appendChild(link);
    link.click();

    // Cleanup
    window.URL.revokeObjectURL(blobUrl);
    document.body.removeChild(link);
  } catch (error: any) {
    toast.error(error.message, TOAST_PROPS);
  }
};
