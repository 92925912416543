import React from "react";

import { DropdownMenuStandardItemProps } from "./types";
import { DropdownLabel } from "./components/DropdownLabel";
import { DropdownOptionProps } from "../../types";

export const DropdownMenuStandardItem = <T,>({
  option,
  ...props
}: DropdownMenuStandardItemProps<T>) => {
  if (React.isValidElement(option)) return <> {option}</>;

  const dropdownOptionProps = option as DropdownOptionProps<T>;

  return (
    <DropdownLabel
      {...{
        ...dropdownOptionProps,
        customOnClick: dropdownOptionProps.onClick,
        ...props,
      }}
    />
  );
};
