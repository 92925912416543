import { RxCross2 } from "react-icons/rx";
import { FC } from "react";

import { Button } from "@/components/Button";
import { useSearchParams } from "@/hooks/useSearchParams";
import { TableFiltersProps } from "./types";
import { removeFilter } from "./utils/removeFilter";
import { removeFilters } from "./utils/removeFilters";
import { TableFiltersLabelGenerator } from "./components/TableFiltersLabelGenerator";

import "./styles.scss";

export const TableFilters: FC<TableFiltersProps> = ({
  filters,
  setFilters,
}) => {
  const { deleteSearchParam } = useSearchParams();
  const removeFilterHandler = removeFilter({
    setFilters,
    filters,
    deleteSearchParam,
  });
  const removeFiltersHandler = () =>
    removeFilters(setFilters, deleteSearchParam, filters);
  const isThereAnyFilter: number = filters.length;

  return isThereAnyFilter ? (
    <div className="filters-container">
      <TableFiltersLabelGenerator {...{ filters, removeFilterHandler }} />
      <Button
        label="Clear Filters"
        size={"small"}
        color={"primary"}
        state={"link"}
        onClick={removeFiltersHandler}
        rightIcon={RxCross2}
      />
    </div>
  ) : null;
};
