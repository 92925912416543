import { FC } from "react";

import { CloseButtonProps } from "./types";

export const CloseButton: FC<CloseButtonProps> = ({ resetDropdownContent }) => (
  <button
    data-testid={"close-button"}
    onClick={() => resetDropdownContent(true)}
    className="close-button"
  >
    &times;
  </button>
);
