import { FC } from "react";

import { TabsProps } from "./types";
import { SubTabs } from "./components/SubTabs";
import { UpperBody } from "./components/UpperBody";
import { useTabsLogic } from "./hooks/useTabsLogic";

export const Tabs: FC<TabsProps> = ({
  title,
  tabs,
  activeSubTab,
  activeTab,
}) => {
  const { handleActiveTab, subTabs, isTabActive } = useTabsLogic(
    tabs,
    activeTab
  );

  return (
    <div className="tabs-container">
      <UpperBody {...{ title, tabs, handleActiveTab, activeTab }} />
      {subTabs ? (
        <SubTabs {...{ subTabs: subTabs || [], activeSubTab, isTabActive }} />
      ) : null}
    </div>
  );
};
