import { RemoveFilterProps } from "./types";

export const removeFilter =
  ({ setFilters, filters, deleteSearchParam }: RemoveFilterProps) =>
  (currentHeader: { title: string; value: string }) => {
    const newFiltersArray = filters.filter(
      (value: Record<string, any>) => value.header !== currentHeader
    );

    deleteSearchParam(currentHeader.value);
    setFilters(newFiltersArray);
  };
