import { FC } from "react";

import { IDType } from "../types";

/**
 * Link to facility edit page
 *
 * @param ID - Facility ID
 */
export const EditFacility: FC<IDType> = ({ recordID: facilityID }) => (
  <a href={`/admin/accounts/organization/${facilityID}/change/`}>Edit</a>
);
