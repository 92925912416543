import { DateRangeWithoutStatus } from "@/components/Table/hooks/useTableLogic/types";

export const getLast7Days = (
  handleUpdateDateRange?: ({
    startDate,
    endDate,
  }: DateRangeWithoutStatus) => void
) => {
  const today = new Date();
  const sevenDaysFromToday = new Date();
  sevenDaysFromToday.setDate(today.getDate() - 6);
  handleUpdateDateRange?.({ startDate: sevenDaysFromToday, endDate: today });
};
