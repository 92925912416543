import { FC } from "react";

import { PaginationRightNavigationProps } from "./types";

import "../scss/index.scss";

export const PaginationRightNavigationArrow: FC<
  PaginationRightNavigationProps
> = ({ currentPage, lastPage, onNext }) => (
  <li
    className={`pagination-item ${
      currentPage === lastPage ? "disabled" : undefined
    }`}
    onClick={onNext}
  >
    <button className="arrow__next">Next</button>
    <div className="arrow right" data-testid="right_arrow" />
  </li>
);
