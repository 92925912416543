import { useEffect, useState } from "react";

import { TableFilters } from "@/components/Table/types/TableFilters";
import { ResponseData } from "./types";

export const useFilters = (
  url: string,
  exclusions?: string[]
): TableFilters => {
  const [filters, setFilters] = useState<Omit<TableFilters, "config">>({
    options: {},
    exportOptions: null,
  });

  useEffect(() => {
    const fetchFilters = async () => {
      try {
        const response: Response = await fetch(url);
        if (!response.ok) throw new Error("Error while fetching filters");
        const { filter_options, export_options }: ResponseData =
          await response.json();

        if (exclusions) {
          const filtersWithoutExclustions = Object.entries(filter_options)
            .filter(([key]) => !exclusions?.includes(key))
            .reduce((previousValue, [key, value]) => {
              return (previousValue = {
                ...previousValue,
                [key]: value,
              });
            }, {});

          return setFilters({
            options: filtersWithoutExclustions,
            exportOptions: export_options,
          });
        }

        setFilters({
          options: filter_options,
          exportOptions: export_options,
        });
      } catch (error) {
        console.error("Error fetching filters:", error);
      }
    };

    fetchFilters();
  }, [url]);

  return filters;
};
