export const formatDate = (date?: Date | null): string => {
  // Extract the month, day, and year from the Date object
  if (!date) return "";

  const month: number = date.getMonth() + 1; // getMonth() returns 0-11, so we add 1
  const day: number = date.getDate();
  const year: number = date.getFullYear();

  // Ensure two digits for month and day
  const formattedMonth: string = month < 10 ? `0${month}` : month.toString();
  const formattedDay: string = day < 10 ? `0${day}` : day.toString();

  // Format the date as YYYY-MM-DD (ISO 8601)
  const formattedDate: string = `${year}-${formattedMonth}-${formattedDay}`;

  return formattedDate;
};
