import { useRef, useState } from "react";
import { LuAlertCircle } from "react-icons/lu";

import { CheckBox } from "@/components/CheckBox";
import { Buttons } from "./components/Buttons";
import { TextBox } from "./components/TextBox";
import { Input } from "./components/Input";
import { EmailInput } from "./components/EmailInput";
import { useCurrentUser } from "@/hooks/useCurrentUser";
import { useData } from "./hooks/useData";
import { EmailType } from "./types";
import { Review } from "./components/Review";
import { sendEmail } from "./utils/sendEmail";
import { getReview } from "./utils/getReview";
import { useReview } from "./hooks/useReview";
import { Alert } from "@/components/Alert";

export const EmailTemplate = (props: Record<string, any>) => {
  const type: EmailType = props.bulkConfig ? props.bulkConfig.type : props.type;
  const isBulk: boolean = !!props.checkboxes.length;

  const user = useCurrentUser();
  const currentUserEmail: string = `${user.full_name} <${user.email}>`;
  const sendCopyRef = useRef(false);

  const sendCopyHandler = (value: boolean) => (sendCopyRef.current = value);
  const { data, dataHandler, isAnyEmail, isAnyMessage, isAnySubject } = useData(
    props,
    type
  );
  const { review, reviewHandler } = useReview();

  const submitReview = () => getReview({ data, reviewHandler });
  const submitEmail = () =>
    sendEmail({ props, data, sendCopy: sendCopyRef.current });

  const anyInactiveUser = props?.checkboxes.some(
    (record: Record<string, any>) => record.is_active === false
  );

  const anyInactiveFacility = props?.checkboxes.some(
    (record: Record<string, any>) => record.status !== "Active"
  );

  const getBulkInactiveEntity = {
    "facility": anyInactiveFacility,
    "user": anyInactiveUser,
  } as const;

  const getInactiveEntity = {
    "facility": props.status !== "Active",
    "user": props.is_active === false,
  } as const;

  const isNotActive: boolean = isBulk
    ? getBulkInactiveEntity[type]
    : getInactiveEntity[type];

  const [displayError, setDisplayError] = useState(isNotActive);
  const displayErrorHandler = (value: boolean) => setDisplayError(value);

  const inactiveRecords = props?.checkboxes
    ?.filter(({ is_active }: { is_active: boolean }) => is_active === false)
    .map(({ email }: any) => email)
    .join(", ");

  const errorMessage: string = isBulk
    ? `The following items couldn't be added: ${inactiveRecords}`
    : "The following item couldn't be added";

  if (review)
    return (
      <Review
        {...{
          review,
          data,
          user,
          reviewHandler,
          sendCopyRef,
          sendCopyHandler,
          onCloseModal: props.onCloseModal,
          submitEmail,
          type,
        }}
      />
    );

  return (
    <div className="custom-mailer-container">
      <div className="custom-mailer-header">Compose Message</div>
      <Input
        {...{
          label: "From",
          inactive: true,
          value: currentUserEmail,
        }}
      />

      {displayError ? (
        <Alert
          icon={LuAlertCircle}
          description={errorMessage}
          color={"destructive"}
          closeButton
        />
      ) : null}

      <EmailInput
        {...{
          label: "BCC",
          emails: data.email_addresses,
          facilities: data.facility_uuids,
          dataHandler: dataHandler("email_addresses"),
          addFacility: dataHandler("facility_uuids"),
          type,
          displayErrorHandler,
        }}
      />
      <Input
        {...{
          label: "Subject",
          dataHandler: dataHandler("subject"),
          value: data.subject,
        }}
      />
      <TextBox
        {...{
          label: "Compose",
          message: data.message,
          dataHandler: dataHandler("message"),
          value: data.message,
        }}
      />
      <CheckBox
        onClick={sendCopyHandler}
        label="Send me a copy of this email for reference"
        defaultChecked={sendCopyRef.current}
      />
      <Buttons
        {...{
          submitReview,
          isAnyEmail,
          isAnySubject,
          isAnyMessage,
          onCloseModal: props.onCloseModal,
        }}
      />
    </div>
  );
};
