import { useState } from "react";

import { ExpandState } from "./types";

export const useExpandState = () => {
  const [expandState, setExpandState] = useState<ExpandState>({});

  const expandStateHandler = (userID: number) => {
    setExpandState((prevExpandState) => ({
      ...prevExpandState,
      [userID]: !prevExpandState[userID],
    }));
  };

  const resetExpandState = () => setExpandState({});

  return {
    expandState,
    expandStateHandler,
    resetExpandState,
  };
};
