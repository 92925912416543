import { BiChevronDown } from "react-icons/bi";

import { Icon } from "@/components/Icon";
import { CloseButton } from "../CloseButton";
import { DropdownSingleSelectSearchableIconProps } from "./types";

import "./scss/index.scss";

export const DropdownSingleSelectSearchableIcon = ({
  inputValue,
  resetDropdownContent,
}: DropdownSingleSelectSearchableIconProps) =>
  inputValue ? (
    <CloseButton {...{ resetDropdownContent }} />
  ) : (
    <div className="down-arrow">
      <Icon
        testID="down-arrow"
        icon={BiChevronDown}
        state={"link"}
        color="neutral"
      />
    </div>
  );
