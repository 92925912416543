import { FC } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";

import { getNavbarIcon } from "./util/getNavbarIcon";
import { TableNavbarIconProps } from "./types";

export const TableNavbarIcon: FC<TableNavbarIconProps> = ({
  type,
  message,
  onOpenModal,
}) =>
  message ? (
    <div className="table-navbar__info-container">
      <a
        data-tooltip-content={message}
        data-tooltip-id={"navbar"}
        data-tooltip-place="top"
        data-testid={"link"}
      >
        {getNavbarIcon()[type]}
      </a>
      <ReactTooltip id="navbar" />
    </div>
  ) : (
    <div className="table-navbar__info-container">
      {getNavbarIcon(onOpenModal)[type]}
      <ReactTooltip place="top" />
    </div>
  );
