import { FC } from "react";

import { getURL } from "@/utils/getURL";

import { UserDetails } from "@/components/Table/templates/UserDetails";
import { Table } from "@/components/Table";

import { AffiliateFacilitiesType } from "./types/affiliateFacilities";

export const AffiliateFacilities: FC = () => {
  const url: string = getURL("api:affiliate-facilities-list", {
    parent_lookup_pk: "current",
  });

  return (
    <Table<AffiliateFacilitiesType>
      id={"facility_id"}
      navbar={{
        header: "Affiliated Facilities",
        input: true,
        tooltip: {
          type: "?",
          message:
            "A list of facilities that belong to a parent organization of which your facility is a part.",
        },
      }}
      dropDown={{
        template: UserDetails,
        props: {
          collapseRow: {
            id: "facility_id",
            primaryMessage: "Primary Contact",
            options: {
              Name: ["primary_contact", "full_name"],
              Title: ["primary_contact", "role"],
              Phone: ["primary_contact", "formatted_office_number"],
              Fax: ["primary_contact", "formatted_office_number"],
              Email: ["primary_contact", "email"],
            },
          },
        },
      }}
      headers={[
        { title: "FACILITY", apiParam: "name" },
        { title: "TYPE", apiParam: "facility_type" },
        { title: "LOCATION", apiParam: "location" },
      ]}
      url={url}
      actions={["view_profile", "submit_disposition_form"]}
    />
  );
};
