import { SetStateAction, useEffect, useState } from "react";

import { useFetch } from "../useFetch";
import { useFilters } from "./hooks/useFilters";
import { useRecords } from "./hooks/useRecords";
import { useCheckbox } from "./hooks/useCheckbox";
import { UseTableLogicProps } from "./types";
import { useExpandState } from "./hooks/useExpandState";
import { FiltersType } from "../../types/TableFilters";
import { useSearchParams } from "@/hooks/useSearchParams";
import { buildFetchUrl } from "../useFetch/utils/buildFetchURL";
import {
  DEFAULT_PAGINATION_CURRENT_PAGE,
  DEFAULT_PAGINATION_PAGE_SIZE,
} from "../../constants";

export const useTableLogic = <T extends Record<string, any>>({
  url,
  id,
  options,
  sortByDefaultValue,
}: UseTableLogicProps) => {
  useEffect(() => {
    !searchParams.has("sort_by") &&
      updateSearchParams("sort_by", sortByDefaultValue);
  }, []);

  const { searchParams, updateSearchParams } = useSearchParams();
  const { expandState, expandStateHandler, resetExpandState } =
    useExpandState();

  const { filters, setFilters, updateFilters } = useFilters(options);
  const [isTableLoading, setIsTableLoading] = useState<boolean>(true);

  const page: number =
    Number(searchParams.get("page")) || DEFAULT_PAGINATION_CURRENT_PAGE;
  const pageSize =
    Number(searchParams.get("page_size")) || DEFAULT_PAGINATION_PAGE_SIZE;
  const input: string = searchParams.get("q") || "";
  const sortBy: string | null =
    searchParams.get("sort_by") || sortByDefaultValue;
  const startDate: string | null = searchParams.get("start_date");
  const endDate: string | null = searchParams.get("end_date");
  const dateStatus: string = searchParams.get("date_field") || "";

  const fetchUrl: string = buildFetchUrl({
    url,
    pageSize,
    page,
    input,
    filters,
    startDate,
    endDate,
    dateStatus,
    sortBy,
  });

  const {
    results,
    error,
    count: allRecordsCount,
  } = useFetch<T>({
    url: fetchUrl,
    filters,
    setIsTableLoading,
  });

  const apiParams: string = fetchUrl.split("?")[1];
  const { updateRecord, records } = useRecords(id, results);

  const {
    checkboxesHandler,
    checkboxes,
    toggleAllCheckboxes,
    toggleCheckbox,
    areAllCheckboxesSelected,
  } = useCheckbox(records);

  const paginationSiblingCount: number = 1;

  return {
    error,
    results,
    updateRecord,
    updateFilters,
    toggleCheckbox,
    toggleAllCheckboxes,
    checkboxes,
    filters,
    setFilters: (value: SetStateAction<FiltersType[]>) => {
      //Reset BulkSelection
      setFilters(value);
      checkboxes.length && checkboxesHandler([]);
    },
    allRecordsCount,
    paginationSiblingCount,
    records,
    checkboxesHandler,
    areAllCheckboxesSelected,
    expandState,
    expandStateHandler,
    resetExpandState,
    apiParams,
    isTableLoading,
    page,
    pageSize,
  };
};
