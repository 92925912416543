import { FC } from "react";

import { getURL } from "@/utils/getURL";
import { Table } from "@/components/Table";
import { GrayFlag, RedFlag } from "../Orders/assets";
import { useFilters } from "@/hooks/useFilters";

const url: string = getURL("api:internal-orders-flagged");
const filtersURL: string = getURL("api:internal-orders-schema-options");

export const FlaggedOrders: FC = () => {
  const filterOptions = useFilters(filtersURL, ["canceled_at"]);

  return (
    <Table
      id={"facility_id"}
      sortBy="-received_at"
      navbar={{ header: "Flagged Orders", input: true }}
      headers={[
        { title: "#", apiParam: "id" },
        { title: "PLACED ON", apiParam: "ordered_at", type: "date" },
        { title: "DELIVERED ON", apiParam: "received_at", type: "date" },
        { title: "TYPE", apiParam: "order_type" },
        { title: "ITEMS", apiParam: "component_string" },
        { title: "BUYER", apiParam: "buyer" },
        { title: "SELLER", apiParam: "seller" },
        { title: "AUDIENCE", apiParam: "audience" },
        {
          title: " ",
          apiParam: "is_flagged",
          type: "boolean",

          properties: {
            booleanType: {
              true: <RedFlag />,
              false: <GrayFlag />,
            },
          },
        },
        {
          title: "STATUS",
          apiParam: "order_status",
          type: "badge",

          properties: {
            badgeColor: {
              "delivered": "success",
              "ordered": "neutral",
              "canceled": "destructive",
              "in-transit": "primary",
            },
          },
        },
      ]}
      filters={filterOptions}
      url={url}
    />
  );
};
