import { useContext } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { BiFilterAlt } from "react-icons/bi";

import { TableNavbarHeader } from "./components/TableNavbarHeader/index";
import { TableNavbarInput } from "./components/TableNavbarInput";
import { TableNavbarIcon } from "./components/TableNavbarHeader/components/TableNavbarIcon";
import { TableContext } from "@/components/Table/context";
import { useModal } from "@/components/Table/hooks/useModal";
import { Button } from "@/components/Button";
import { FiltersContainer } from "../FiltersContainer";
import { PermissionsModal } from "@/ReactTables/UserAdministration/template/PermissionsModal";
import { TableNavbarProps } from "./types/tableNavbar";
import { TableNavbarShareButton } from "./components/TableNavbarShareButton";
import { DatePicker } from "./components/DatePicker";
import { DownloadButton } from "./components/DownloadButton";

import "./style.scss";

export const TableNavbar = ({ input }: TableNavbarProps): JSX.Element => {
  const { modal, onOpenModal } = useModal(PermissionsModal);

  const { navbar, updateFilters, filters, addRecord } =
    useContext(TableContext);
  const { type, message } = navbar?.tooltip ?? {};

  const isDateFilterActive: boolean = filters
    ? Object.values(filters?.options!).some(({ type }) => type === "date")
    : false;

  return (
    <div className="table-navbar">
      <TableNavbarHeader />
      {input ? (
        <div className="input-container">
          <div className="icons-container">
            {addRecord ? (
              <Button
                leftIcon={AiOutlinePlus}
                label="New Order"
                size={"small"}
                color={"primary"}
                state={"primary"}
                className={"new-button"}
              />
            ) : null}
            {isDateFilterActive ? <DatePicker /> : null}
            <TableNavbarShareButton />
            {filters?.exportOptions ? (
              <DownloadButton {...{ ...filters.exportOptions }} />
            ) : null}
            {filters ? (
              <FiltersContainer
                {...{
                  updateFilters,
                  filters,
                  icon: BiFilterAlt,
                  size: "medium",
                  color: "neutral",
                  style: "link",
                }}
              />
            ) : null}
            {type ? (
              <TableNavbarIcon {...{ type, message, onOpenModal }} />
            ) : null}
            {/* <HeaderIcon {...{ type, message, onOpenModal }} /> */}
          </div>
          {modal}
          <TableNavbarInput />
        </div>
      ) : null}
    </div>
  );
};
