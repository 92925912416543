import { RxCross2 } from "react-icons/rx";
import { AiOutlinePlus } from "react-icons/ai";

import { Badge } from "@/components/Badge";
import { FiltersContainer } from "../../../FiltersContainer";
import { TableFiltersLabelGeneratorProps } from "./types";

export const TableFiltersLabelGenerator = ({
  filters,
  removeFilterHandler,
}: TableFiltersLabelGeneratorProps) => (
  <div className="filters">
    <div className="options">
      <div className="header">Filters:</div>
      {filters.map(({ header, subHeader }, index) => (
        <Badge
          key={index}
          label={`${header.title}: ${subHeader.title}`}
          size={"medium"}
          color={"primary"}
          state={"secondary"}
          className={"active-filter"}
          rightIcon={RxCross2}
          onClick={() => removeFilterHandler(header)}
        />
      ))}

      <FiltersContainer
        {...{
          label: "Add More Filters",
          icon: AiOutlinePlus,
          size: "medium",
          color: "primary",
          style: "outlined",
          className: "add-filter-button",
          type: "label",
        }}
      />
    </div>
  </div>
);
