import { Fragment, useContext } from "react";

import { TableCollapseRowItem } from "./components/TableCollapseRowItem";
import { TableRowItem } from "./components/TableRowItem";
import { TableContext } from "@/components/Table/context";
import { TableRowsProps, UseTableRows } from "./types";

export const useTableRows = ({
  records,
  rowHeaders,
}: TableRowsProps): UseTableRows => {
  const { dropDown, expandState, expandStateHandler, resetExpandState } =
    useContext(TableContext);

  const { template: Template, props } = dropDown || {};
  const numberOfExtraHeaders: number = 2;

  return {
    resetExpandState,

    render: (
      <>
        {records.map((record) => {
          const recordID: number = record.id;

          return (
            <Fragment key={recordID}>
              <TableRowItem
                {...{
                  record,
                  expandStateHandler,
                  expandState,
                  rowHeaders,
                  isDropDown: dropDown && true,
                }}
              />

              {dropDown ? (
                <TableCollapseRowItem
                  {...{
                    numberOfHeaders: rowHeaders.length + numberOfExtraHeaders,
                    expandState,
                    recordID,
                  }}
                >
                  {Template ? <Template {...{ record, ...props }} /> : null}
                </TableCollapseRowItem>
              ) : null}
            </Fragment>
          );
        })}
      </>
    ),
  };
};
