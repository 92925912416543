import { FC } from "react";

import { IconProps } from "./types";

import "./scss/index.scss";

export const Icon: FC<IconProps> = ({
  icon: Icon,
  testID = "icon",
  color,
  size,
  disabled,
  state,
  onClick,
  className = "",
  type: Type = "div",
  colorClassName = "",
}) => {
  const notClickable: string = !onClick ? "not-clickable" : "";
  const containerClass: string = `icon-container ${color} ${state} ${size} ${notClickable} ${className}`;
  const iconClassName: string = `icon ${
    disabled ? "disabled" : ""
  } ${colorClassName}`;

  return Icon ? (
    <Type
      data-testid="icon-container"
      onClick={onClick}
      className={containerClass}
      disabled={disabled}
    >
      <div className={`icon-sub-container ${size}`}>
        <Icon
          preserveAspectRatio="xMidYMid meet"
          data-testid={testID}
          className={iconClassName}
        />
      </div>
    </Type>
  ) : null;
};
