import { FC } from "react";
import { IoClose } from "react-icons/io5";

import { AlertProps } from "./types";
import { Icon } from "../Icon";
import { useVisible } from "./hooks/visible";
import { AlertInfo } from "./components/AlertInfo";

import "./scss/index.scss";

/**
 * {@link Alert | Alert}
 *
 * @param header - Primary message
 * @param description - Sub message
 * @param actions - Conditional functions to render, max (2)
 * @param icon - Conditional Icon to render inside the component
 * @param color - Color to render the component, options "primary" | "destructive" | "success" | "warning" | "neutral"
 *
 * @returns JSX element, Alert component
 *
 * @example
 * Simple usage:
 * ```
 * <Alert description={"Test"} color={"primary"}/>
 * ```
 *
 * @example
 * With header:
 * <Alert description={"Test"} color={"primary"} header={'test-header'}/>
 *
 * @example
 * With actions
 * <Alert description={"Test"} color={"primary"} header={'test-header'} actions={[{ label: "test", onClick: () => alert(1) }]} />
 *
 * @example
 * With icon
 * <Alert description={"Test"} color={"primary"} icon={IOIcon}/>
 *
 * @privateRemarks
 * {@link https://bloodbuy.atlassian.net/browse/BB-4413 - Jira Story}
 *
 * {@link https://www.figma.com/file/YSqy7gfEnOCAYZYHn88ZwU/Bloodbuy-Alerts-Inline?node-id=0%3A1&t=QX2nRYyLTha6NNvI-0 - Figma design}
 */
export const Alert: FC<AlertProps> = ({
  header,
  description,
  actions,
  icon,
  color,
  closeButton,
}): JSX.Element | null => {
  const { visible, toggleVisible } = useVisible();

  if (!visible) return null;

  return (
    <div className={`alert-container ${color}`}>
      <AlertInfo {...{ header, description, actions, icon, color }} />
      {closeButton ? (
        <Icon
          testID="close-button"
          colorClassName="alert-close-icon"
          color={color}
          icon={IoClose}
          state={"link"}
          onClick={toggleVisible}
        />
      ) : null}
    </div>
  );
};
