import { TableCollapseRowItemType } from "./types/tableCollapseRowItem";

import "./styles.scss";

/**
 * Generates collapse rows inside the table
 *
 * @param numberOfHeaders- The amount of headers in current table
 * @param expandState - Object with the expanded rows of the table
 * @param children - Element to render inside the component
 * @param recordID - ID of the given record
 */
export const TableCollapseRowItem: React.FC<TableCollapseRowItemType> = ({
  numberOfHeaders,
  expandState,
  children,
  recordID,
}) => {
  const dataCellClassName = expandState?.[recordID]
    ? "collapse--show"
    : "collapse--collapse";

  const divClassName = expandState?.[recordID]
    ? "collapse-container--show"
    : "collapse-container--collapse";

  return (
    <tr>
      <td colSpan={numberOfHeaders} className={dataCellClassName}>
        <div className={divClassName}>{children}</div>
      </td>
    </tr>
  );
};
