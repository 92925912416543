import { FC, MouseEvent } from "react";

import { CheckBoxProps } from "./types";

import "./scss/index.scss";

/**
 * Checkbox component.
 *
 * @param {string} [label] - Label text for the checkbox.
 * @param {string} [id] - ID for the checkbox input element.
 * @param {boolean} [defaultChecked] - Initial checked state of the checkbox.
 * @param {void} [onClick] - Function to handle checkbox click events.
 * @param {boolean} [checked] - Current checked state of the checkbox.
 *
 * @returns {JSX.Element} - Checkbox component.
 */
export const CheckBox: FC<CheckBoxProps> = ({
  label,
  id,
  defaultChecked,
  onClick,
  checked,
}) => (
  <label data-testid="label-element" className="checkbox-container">
    {label}
    <input
      id={label}
      className="checkbox"
      type={"checkbox"}
      checked={checked}
      defaultChecked={defaultChecked}
      onClick={(event: MouseEvent<HTMLInputElement>) =>
        onClick?.((event.target as HTMLInputElement).checked)
      }
      value={id}
      readOnly
    ></input>
    <span data-testid="span-element" className="checkmark"></span>
  </label>
);
