import React from "react";

import { SearchParam } from "./types";

//Creates a reducer that forces a component to re-render
function useForceUpdate(): () => void {
  return React.useReducer(() => ({}), {})[1] as () => void; 
}

export const useSearchParams = (): SearchParam => {
  const { location, history } = window;
  const { search, pathname } = location;
  const searchParams: URLSearchParams = new URLSearchParams(search);

  const newUrl = () => `${location.pathname}?${searchParams.toString()}`;
  const forceUpdate = useForceUpdate();

  const updateSearchParams = (name: string, value: string) => {
    searchParams.set(name, value);
    history.pushState(null, "", newUrl());
    forceUpdate();
  };

  const deleteSearchParam = (name: string, value?: string) => {
    searchParams.delete(name, value);
    history.pushState(null, "", newUrl());
  };

  const deleteAllSearchParams = () => {
    const newSearchParams: URLSearchParams = new URLSearchParams();
    const query: string = "q";

    const queryParamValue: string | null = searchParams.get(query);

    queryParamValue && newSearchParams.set(query, queryParamValue);

    history.pushState(
      null,
      "",
      `${location.pathname}?${newSearchParams.toString()}`
    );
  };

  const currentUrlPath: string[] = pathname
    .split("/")
    .filter((nonEmptyValue) => nonEmptyValue);

  return {
    searchParams,
    updateSearchParams,
    deleteSearchParam,
    deleteAllSearchParams,
    currentUrlPath,
  };
};
