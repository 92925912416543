import { createRoot } from "react-dom/client";

import "./scss/index.scss";

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

//TODO?: BB-4306: add web analytics - https://bloodbuy.atlassian.net/browse/BB-4306
// import reportWebVitals from "./reportWebVitals";

type Todo = any;

(function () {
  createElement("OpenOrders", () => import("./ReactTables/OpenOrders"));
  createElement(
    "ActiveOpenOrders",
    () => import("./ReactTables/ActiveOpenOrders")
  );
  createElement(
    "DirectOpenOrders",
    () => import("./ReactTables/DirectOpenOrders")
  );
  createElement(
    "FulfilledOpenOrders",
    () => import("./ReactTables/FulfilledOpenOrders")
  );
  createElement(
    "ArchivedOpenOrders",
    () => import("./ReactTables/ArchivedOpenOrders")
  );
  createElement("FacilityUsers", () => import("./ReactTables/FacilityUsers"));
  createElement("Packages", () => import("./ReactTables/Packages"));
  createElement("OnlinePackages", () => import("./ReactTables/OnlinePackages"));
  createElement(
    "OfflinePackages",
    () => import("./ReactTables/OfflinePackages")
  );
  createElement(
    "PurchasedPackages",
    () => import("./ReactTables/PurchasedPackages")
  );
  createElement(
    "ArchivedPackages",
    () => import("./ReactTables/ArchivedPackages")
  );
  createElement(
    "PrivatePackages",
    () => import("./ReactTables/PrivatePackages")
  );
  createElement("StandingOrders", () => import("./ReactTables/StandingOrders"));
  createElement(
    "TriggeringSoonStandingOrders",
    () => import("./ReactTables/TriggeringSoonStandingOrders")
  );
  createElement(
    "PausedStandingOrders",
    () => import("./ReactTables/PausedStandingOrders")
  );
  createElement(
    "InactiveStandingOrders",
    () => import("./ReactTables/InactiveStandingOrders")
  );
  createElement(
    "ActiveStandingOrders",
    () => import("./ReactTables/ActiveStandingOrders")
  );
  createElement("Orders", () => import("./ReactTables/Orders"));
  createElement("OrderedOrders", () => import("./ReactTables/OrderedOrders"));
  createElement("CanceledOrders", () => import("./ReactTables/CanceledOrders"));
  createElement(
    "DeliveredOrders",
    () => import("./ReactTables/DeliveredOrders")
  );
  createElement("FlaggedOrders", () => import("./ReactTables/FlaggedOrders"));
  createElement(
    "InTransitOrders",
    () => import("./ReactTables/InTransitOrders")
  );
  createElement(
    "UserAdministration",
    () => import("./ReactTables/UserAdministration")
  );
  createElement(
    "FacilityAdminActive",
    () => import("./ReactTables/FacilityAdminActive")
  );
  createElement(
    "AffiliateFacilities",
    () => import("./ReactTables/AffiliateFacilities")
  );
  createElement(
    "NetworkFacilities",
    () => import("./ReactTables/NetworkFacilities")
  );
  createElement(
    "BlockedFacilities",
    () => import("./ReactTables/BlockedFacilities")
  );
  createElement("AdminToolsTabs", () => import("./ReactTabs/AdminToolsTabs"));
  createElement("OrderToolsTabs", () => import("./ReactTabs/OrderToolsTabs"));
})();

async function createElement(
  elementId: string,
  moduleImport: () => Promise<Todo>
) {
  const ElementContainer = document.getElementById(elementId);
  if (!ElementContainer) return;

  const { [elementId]: ElementComponent } = await moduleImport();
  const root = createRoot(ElementContainer as Element);
  root.render(<ElementComponent />);
}
