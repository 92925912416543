import { FC } from "react";

import { UpperTabs } from "./components/UpperTabs";
import { UpperBodyProps } from "./types";

import "./scss/index.scss";

export const UpperBody: FC<UpperBodyProps> = ({
  title,
  tabs,
  handleActiveTab,
  activeTab,
}) => (
  <div className="upper-body">
    <div className="header">{title}</div>
    <UpperTabs {...{ tabs, handleActiveTab, activeTab }} />
  </div>
);
