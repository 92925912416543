import { FC } from "react";

import { PaginationLeftNavigationArrowProps } from "./types";

import "../scss/index.scss";

export const PaginationLeftNavigationArrow: FC<
  PaginationLeftNavigationArrowProps
> = ({ currentPage, onPrevious }) => (
  <li
    className={`pagination-item ${currentPage === 1 ? "disabled" : undefined}`}
    onClick={onPrevious}
  >
    <div className="arrow left" data-testid="left_arrow" />
    <button className="arrow__previous">Previous</button>
  </li>
);
