import { DropdownMenu } from "@/components/DropdownMenu";
import { FilteredDropdownMenuProps } from "./types";

import "./scss/index.scss";

export const FilteredDropdownMenu = <T,>({
  open,
  isAnyFilteredOption,
  filteredOptions,
  dropdownMenuProps,
  onClick,
  selectedOption,
}: FilteredDropdownMenuProps<T>) => {
  if (!open) return null;

  return isAnyFilteredOption ? (
    <DropdownMenu
      {...{
        options: filteredOptions,
        ...dropdownMenuProps,
        onClick,
        selectedOption,
      }}
    />
  ) : (
    <div className="drop-down-no-results">No results</div>
  );
};
