export const extractStringsInAngleBrackets = (input: string[]): string[] => {
  const extractedStrings: string[] = [];

  input.forEach((input: string) => {
    const match =input.match(/<(.*?)>/);
    if (match && match.length > 1) extractedStrings.push(match[1]);
  });

  return extractedStrings;
};
