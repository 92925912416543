import { Icon } from "@/components/Icon";
import { AlertInfoProps } from "./types";
import { DetailsContainer } from "./components/DetailsContainer";

import "./scss/index.scss";

export const AlertInfo = ({
  icon,
  color,
  header,
  description,
  actions,
}: AlertInfoProps) => (
  <div className="alert-info-container">
    {icon ? (
      <Icon
        {...{
          icon,
          colorClassName: "alert-icon",
          color,
          state: "link",
          testID: "alert-icon",
        }}
      />
    ) : null}
    <DetailsContainer {...{ header, description, actions, color }} />
  </div>
);
